import React from "react";

export const CardInfo = (props) => (
  <div
    className="io-card info"
    onMouseOver={(e) => blur(e, true)}
    onMouseOut={(e) => blur(e, false)}
  >
    <span />
    {props.title && <h3>{props.title}</h3>}
    {props.description && <p>{props.description}</p>}
  </div>
);

function blur(e, t) {
  let content = document.querySelector(".list-services");
  if (t) {
    content.classList.add("active");
  } else {
    content.classList.remove("active");
  }
}
