import React from "react";
import {message} from "antd";
import {Contact} from "../controllers/contact"
import {Loader} from "../display/Loader";
import ReactGA from "react-ga";
import {Link} from "react-router-dom";

import {useState,useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

let map = null;
let div;
const captchaRef = useRef<ReCAPTCHA>(null);
let token = '';

const template_register_marker = `
<div class="point-marker"></div>
`
const google = window.google;

export class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      name: '',
      email: '',
      company: '',
      phone: '',
      ruc: '',
      tokenRecaptcha:'',
      verfiedRecaptcha: false,
      message: ''
    }
  }

  onChange = (value) => {
    console.log("Captcha value:", value);
    token = value;
    this.setState({verfiedRecaptcha:true});
  }


  componentDidMount() {
    if (this.props.map) {
      let lat = -12.1276962;
      let lng = -77.0123423;
      this.createMap(lat, lng, google);
      this.createMarker(map, lat, lng, google, {template: template_register_marker})
    }

  }

  createMap = (lat, lng, google) => {
    map = new google.maps.Map(document.getElementById('map'), {
      zoom: 14,
      minZoom: 10,
      maxZoom: 16,
      streetViewControl: true,
      mapTypeControl: false,
      center: {lat: lat - .002, lng: lng},
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      // styles: this.state.theme,
      disableDefaultUI: true,
      // gestureHandling: "greedy",
      gestureHandling: "cooperative",
      visualRefresh: true
    });
  }

  createMarker = (map, lat, lng, google, config) => {
    let htmlMarker = null

    function HTMLMarker(lat, lng) {
      this.lat = lat;
      this.lng = lng;
      this.pos = new google.maps.LatLng(lat, lng);
      
    }

    HTMLMarker.prototype = new google.maps.OverlayView();
    HTMLMarker.prototype.onRemove = function () {
      div.parentNode.removeChild(div);
    }

    let all = document.getElementsByClassName(config.className)
    for (let item of all) {
      item.remove()
    }
    //init your html element here
    HTMLMarker.prototype.onAdd = function () {
      let div = document.createElement('div');
      div.className = config.className || "io-marker-map";
      div.innerHTML = config.template || null;
      let panes = this.getPanes();
      panes.overlayImage.appendChild(div);
    }

    HTMLMarker.prototype.draw = function () {
      let overlayProjection = this.getProjection();
      let position = overlayProjection.fromLatLngToDivPixel(this.pos);
      let panes = this.getPanes();
      panes.overlayImage.style.left = position.x + 'px';
      panes.overlayImage.style.top = position.y + 'px';
    }

    //to use it
    htmlMarker = new HTMLMarker(lat, lng);
    htmlMarker.setMap(map);
  }

  onChangeHandler = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  submitHandler = async () => {
    try {
      if (!/^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,3}$/.test(this.state.name))
        throw {message: "Ingrese un nombre válido. (Máximo 2 nombres y 2 apellidos, sin carecteres especiales ni acentos)"}
      if (!/^[0-9]{9}$/.test(this.state.phone))
        throw {message: "Ingrese un teléfono válido. (números sin espacios de 9 cifras)"}
      if (!/^[0-9]{11}$/.test(this.state.ruc))
        throw {message: "Ingrese un RUC válido."}
      if (!/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(this.state.email))
        throw {message: "Ingrese un email válido. (example@domain.com)"}
      if (this.state.message.length == 0)
        throw {message: "Ingrese un mensaje."}
      this.setState({loader: true})

      if(token){
        //let valid_token = await verifyToken(token);
        this.state.tokenRecaptcha = token;

        let contact = new Contact()
          let response = await contact.add(this.state)
          if (!response.status)
            throw {message: response.message}
          message.success('Registro exitoso, en breve nos pondremos en contacto.');
          this.setState({
            name: '', phone: '', ruc: '', email: '', company: '', ruc: '', message: '', 
          });

        

          ReactGA.event({
            category: 'contact us',
            action: 'Envio exitoso de formulario',
            label: 'contactanos'
          });   

          
       
    }else{
      console.log("You must confirm you are not a robot");
      //setError("You must confirm you are not a robot");
    }

           
    } catch (ex) {
      message.error(ex.message)
      ReactGA.event({
        category: 'contact us',
        action: 'Error al enviar formulario',
        label: 'contactanos'
      });
    } finally {
      this.setState({loader: false})
    }
  }

  route = (r) => {
    ReactGA.event({
      category: 'social networks',
      action: 'Ruta a ' + r,
      label: 'Facebook'
    });
  }

  render() {
    const {name, email, company, phone, ruc, message} = this.state
    return (
      <>
        <section className="all-bottom" id="contact" style={{paddingTop: this.props.map ? 0 : '60px'}}>
          {this.props.map && <div className="io-map" id="map"/>}
          <div className="io-box-form ">
            <div className="content">
              <div className={this.props.map ? "address map" : "address"}>
                <p className="title">Encuéntranos</p>
                <div className="io-form-group">
                  <span>Dirección:</span>
                  <a target="_blank" href="https://maps.google.com/?q=Logixtal">Av. Alfredo Benavides 2150,
                    Oficina 504
                    Miraflores.</a>
                </div>
                <div className="io-form-group">
                  <span>Email:</span>
                  <a href="mailto:info@logixtal.com">info@logixtal.com</a>
                </div>
                <div className="io-form-group">
                  <span>Telefono:</span>
                  <p><a href="tel:+5117091740">(+51 1) 709-1740</a></p>
                </div>
                <div className="io-grid grid-2">
                  <div className="io-form-group">
                    <span>Fanpage:</span>
                    <a className='link-btn' onClick={() => this.route('Facebook')}
                       href='https://www.facebook.com/Logixtal-103647478031241' target='_blank'>
                      <svg fill='currentColor' viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h151v-181h-60v-90h60v-61c0-49.628906 40.371094-90 90-90h91v90h-91v61h91l-15 90h-76v181h121c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm0 0"/>
                      </svg>
                      <span>Logixtal</span>
                    </a>
                  </div>
                  <div className="io-form-group">
                    <span>LinkedIn:</span>
                    <a className='link-btn' onClick={() => this.route('Linkedin')}
                       href='https://www.linkedin.com/company/logixtal-sac/' target='_blank'>
                      <svg fill='currentColor' viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-256 406h-60v-210h60zm0-240h-60v-60h60zm210 240h-60v-120c0-16.539062-13.460938-30-30-30s-30 13.460938-30 30v120h-60v-210h60v11.308594c15.71875-4.886719 25.929688-11.308594 45-11.308594 40.691406.042969 75 36.546875 75 79.6875zm0 0"/>
                      </svg>
                      <span>Logixtal</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className={this.props.map ? "form map" : "form"}>
                <p className="title">Contáctanos</p>
                <div className="io-form-group">
                  <span>Nombre completo:</span>
                  <input type="text" name="name" placeholder="Nombre completo" value={name}
                         onChange={this.onChangeHandler}/>
                </div>
                <div className="io-grid grid-md-2">
                  <div className="io-form-group">
                    <span>Celular:</span>
                    <input type="number" name="phone" placeholder="Celular" value={phone}
                           onChange={this.onChangeHandler}/>
                  </div>
                  <div className="io-form-group">
                    <span>Correo electrónico:</span>
                    <input type="email" name="email" placeholder="Correo electrónico" value={email}
                           onChange={this.onChangeHandler}/>
                  </div>
                </div>
                <div className="io-grid grid-md-2">
                  <div className="io-form-group">
                    <span>Nombre de la empresa:</span>
                    <input type="text" name="company" placeholder="Nombre de la empresa" value={company}
                           onChange={this.onChangeHandler}/>
                  </div>
                  <div className="io-form-group">
                    <span>RUC Empresa:</span>
                    <input type="number" name="ruc" placeholder="RUC Empresa" value={ruc}
                           onChange={this.onChangeHandler}/>
                  </div>
                </div>
                <div className="io-form-group">
                  <span>Mensaje:</span>
                  <textarea name="message" placeholder="Mensaje..." value={message} onChange={this.onChangeHandler}/>
                </div>

                <div className="io-grid grid-md-2">
                <ReCAPTCHA 
              sitekey={process.env.REACT_APP_SITE_KEY} 
              onChange={this.onChange}
              ref={captchaRef}  />

                  <div  className={(this.state.verfiedRecaptcha) ? "io-btn primary" : "io-btn primary desability"} onClick={this.submitHandler}>Enviar</div>
                </div>
              </div>
            </div>
          </div>
          <div className="all">
            <div className="left">
              © {new Date().getFullYear()} Logixtal, todo los derechos reservados.
            </div>
            <div className="right">
              <Link to="/politicas-de-privacidad">Políticas de privacidad</Link>
              <Link to="/trabaja-con-nosotros">Trabaja con Nosotros </Link>
            </div>
          </div>
        </section>
        {this.state.loader && <Loader message='Enviando datos'/>}
      </>
    )
  }
}

