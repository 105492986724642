import React from "react";
import ReactGA from "react-ga";
import {Link} from "react-router-dom";
import {Icon, isTablet} from "../../io/io";
import CardCategory from "../cards/Category";
import CardProject from "../cards/Project";

export class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true
    }
  }

  link = (r) => {
    window.location.href = r
  }

  navigation = (n) => {
    ReactGA.event({
      category: 'navigation',
      action: n,
      label: 'mobile navigation'
    });
  }

  services = (s) => {
    ReactGA.event({
      category: 'card service',
      action: 'Servicio ' + s,
      label: 'navigation card services'
    });
  }

  toggleExpand = (n) => {
    this.navigation(n)
    if (!isTablet) {
      this.setState({expand: !this.state.expand})
    }
  }

  render() {
    const {expand} = this.state;
    return (
      <article className={this.props.visible ? "io-fix-services open" : "io-fix-services"}>
        <div className="mask" onClick={this.props.onClose}/>
        <div className="content">
          <div className="top">
            <div className="close" onClick={this.props.onClose}>
              Cerrar
              <svg viewBox="0 0 352 512">
                <g className="fa-group">
                  <path fill="currentColor"
                        d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
                        opacity=".6"/>
                  <path fill="currentColor"
                        d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"/>
                </g>
              </svg>
            </div>
          </div>
          <Link className="touch no-tablet" onClick={() => this.navigation('Inicio')} to="/">Inicio</Link>
          <div className={expand ? "touch active" : "touch"} onClick={() => this.toggleExpand('Servicios')}>
            SOLUCIONES
            <svg viewBox="0 0 320 512">
              <path fill="currentColor"
                    d="M188.74 256l56.78 56.89L91.21 466.9a24 24 0 0 1-33.94 0l-22.7-22.65a23.93 23.93 0 0 1 0-33.84z"
                    opacity=".6"/>
              <path fill="currentColor"
                    d="M91.25 45.06l194.33 194a23.93 23.93 0 0 1 0 33.84l-40 40-211-211.34a23.92 23.92 0 0 1 0-33.84l22.7-22.65a24 24 0 0 1 33.97-.01z"/>
            </svg>
          </div>
          <div className={expand ? "box-services expand" : "box-services"}>
            <p>Soluciones Logísticas Integrales</p>
            {data.map((card, index) => (
              card.type === 'category' && (
                <CardCategory
                  key={index}
                  image={card.image}
                  title={card.title}
                  description={card.description}
                  route={card.route}
                  onClick={() => this.services(card.route)}
                  min
                />
              )
            ))}
            
          </div>
          <a className="touch no-tablet" onClick={() => this.navigation('Contacto')} href="#contact">Contacto</a>
          <Link className="touch no-tablet" onClick={() => this.navigation('Trabaja con nosotros')}
                to="/trabaja-con-nosotros">Trabaja con Nosotros</Link>
        </div>
      </article>
    )
  }
}

const data = [
  {
    image: require('../../assets/image/madit/1-Madit.jpg'),
    icon: 'io-bpo-fav',
    title: 'BPO',
    description: 'Externalización de Procesos de Negocios',
    route: '/bpo',
    type: 'service',
  },
  {
    image: require('../../assets/image/madit/5-BiometriaFacialMadit.jpg'),
    icon: 'io-madit-fav',
    title: 'MADIT',
    description: 'Sistema de validación biométrica y dactilar',
    route: '/madit',
    type: 'service',
  },
  {
    image: require('../../assets/image/logitrack/1-LogiTrack.jpg'),
    icon: 'io-logitrack-fav',
    title: 'LOGITRACK',
    description: 'Plataforma de Delivery Modular, Integrada e Inteligente',
    route: '/logitrack',
    type: 'service',
  },
  // {
  //   image: require('../../assets/image/home/home-veridomi.jpg'),
  //   icon: 'io-veridomi-fav',
  //   title: 'VERIDOMI',
  //   description: 'Verificaciones Domiciliarias y Laborales',
  //   route: '/veridomi',
  //   type: 'service',
  // },
  {
    image: require('../../assets/image/medixtal/medixtal.jpg'),
    icon: 'io-medixtal-fav',
    title: 'MEDIXTAL',
    description: 'Línea médica de Logixtal',
    route: '/medixtal',
    type: 'product',
  },
  {
    image: require("../../assets/image/read/1.jpg"),
    title: "Distribución de valorados",
    description:
      "Brindamos condiciones de seguridad desde la recepción del valorado (Tarjetas de crédito / Celulares) hasta la entrega al destinatario.",
    route: "/distribucion-valorados",
    type: "category",
  },
  {
    image: require("../../assets/image/read/7.jpg"),
    title: "Desarrollo de Software (SaaS)",
    description:
      "Logixtal, tu aliado en innovación tecnológica. ¿Qué podemos hacer por ti? Lo que NECESITES.",
    route: "/desarrollo-softwares",
    type: "category",
  },
  {
    image: require("../../assets/image/read/4.jpg"),
    title: "Plataforma de validación biométrica",
    description:
      "Cumplimos con todos los protocolos para corroborar identificaciones de personas mediante la biometría dactilar y facial",
    route: "/validadcion-biometrica",
    type: "category",
  },
  {
    image: require("../../assets/image/read/3.jpg"),
    title: "Logística y transporte para Ecommerce",
    description:
      "Somos el nexo o puente entre los productos de una empresa y los clientes finales.",
    route: "/logistica-transporte-ecommerce",
    type: "category",
  },
  // {
  //   image: require("../../assets/image/read/8.jpg"),
  //   title: "RECUPERO DE VALORADOS Logistica Inversa",
  //   description:
  //     "Nuestro servicio se enfoca en la recuperación de productos de inventario, Garantizamos la máxima calidad y eficiencia para minimizar pérdidas y maximizar ingresos adicionales.",
  //   route: "#",
  //   type: "category",
  // },
  
  
  
]
