import React from "react";

export const Loader = (props) => {
  if (props.message) {
    return (
      <div className='io-loader'>
        <div className="content">
          <div className="loader-circle"/>
          <div className="loader-line-mask">
            <div className="loader-line"/>
          </div>
          <p className='text'>{props.message}</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className='io-loader'>
        <div className='message'>
          <div>O</div>
          <div>D</div>
          <div>N</div>
          <div>A</div>
          <div>G</div>
          <div>R</div>
          <div>A</div>
          <div>C</div>
        </div>
      </div>
    )
  }
}