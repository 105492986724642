import React from "react";
import {Master} from "../components/layout/Master";
import {Checkbox, message} from 'antd';
//import { ReCaptcha } from 'react-recaptcha-v3'
import {UploadOutlined} from '@ant-design/icons';
import {Postulant} from "../components/controllers/postulant"
import {Link} from "react-router-dom";
import {Loader} from "../components/display/Loader";
import ReactGA from "react-ga";

import {useState,useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';



const captchaRef = useRef<ReCAPTCHA>(null);
let token = '';
//const [verfied, setverfied] = useState(false);

const key_website = '6LdXFcwZAAAAAL1gctMETbz4Uw3YJvhKB4qwQzBC';
export class Works extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      name: '',
      email: '',
      dni: '',
      phone: '',
      privacy: false,
      position: '',
      cv: '',
      cvname: '',
      verfied: false,
      verfiedRecaptcha: false,
      token: '',
      tokenRecaptcha: '',
      websecretKey: ''
    };
   
  }


  onChange = (value) => {
    console.log("Captcha value:", value);
    token = value;
    this.setState({verfiedRecaptcha:true});
  }

  verifyCallback = (recaptchaToken) => {
    console.log(recaptchaToken, "<= your recaptcha token")
  }



  updateToken = () => {
    this.recaptcha.execute();
    console.log('obteniendo nuevo tokken')
  }

  onPrivacyChange = (e) => {
    this.state.privacy = e.target.checked;
    //this.setState({verfied:!this.state.verfied});
    //this.setState({this.state.verfied:true});
    console.log('checkBox TITO '+this.state.verfied);
    //this.state.verfied
  }

  onChangeHandler = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  onUploadFile = (e) => {
    let input = document.querySelector("input[type='file'][name='cv']")
    if (input != null)
      input.click()
  }
  onFileSelected = (e) => {
    try {      
      this.state.cv = ''
      this.state.cvname = ''
      if (!e.target.files.length > 0)
        throw {message: "No se seleccionó ningún archivo."}
      let file = e.target.files[0]
      if (!/[a-zA-Z0-9_-]{1,}\.(pdf|docx|jpg|png)/i.test(file.name))
        throw {message: "Debe adjuntar archivo pdf, docx, jpg o png y sin caracteres especiales."}
      if (file.size > 1 * 1024 * 1024)
        throw {message: "Debe adjuntar un archivo menor a 1 MB."}
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        let base64 = e.target.result.replace(/^[^,]*,/, '')
        this.state.cv = base64
        this.state.cvname = file.name
      }
      reader.onerror = (error) => {
        message.error("Error al cargar el archivo.")
        console.log(error)
      };
    } catch (ex) {
      message.error(ex.message);
    }
  }

  submitHandler = async () => {
    try {
      //console.log(this.state)
      if (!this.state.privacy)
        throw {message: "Debe aceptar la política de privacidad."}
      if (!/^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,3}$/.test(this.state.name))
        throw {message: "Ingrese un nombre válido. (Máximo 2 nombres y 2 apellidos, sin carecteres especiales ni acentos)"}
      if (!/^[0-9]{9}$/.test(this.state.phone))
        throw {message: "Ingrese un teléfono válido. (números sin espacios de 9 cifras)"}
      if (!/^[0-9]{8}$/.test(this.state.dni))
        throw {message: "Ingrese un DNI válido."}
      if (!/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(this.state.email))
        throw {message: "Ingrese un email válido. (example@domain.com)"}
      if (this.state.position.length == 0)
        throw {message: "Ingrese el puesto al que desea postular."}
      if (this.state.cv == 0)
        throw {message: "Debe adjuntar su CV."}
      this.setState({loader: true})

      //let token = captchaRef.getValue();
        console.log("TITO TOKEN generado: "+token);
        if(token){
              this.state.tokenRecaptcha = token;
              let postulant = new Postulant()
              let response = await postulant.add(this.state)
              if (!response.status)
                throw {message: response.message}
              // console.log(localStorage.getItem('cookies'))
              message.success('Registro exitoso, en breve nos pondremos en contacto.');

              this.setState({
                name: '', email: '', phone: '', dni: '', position: '', cv: '', cvname: '', 
              });

              this.setState({verfied:false});

              
              ReactGA.event({
                category: 'work with us',
                action: 'Envio exitoso de formulario',
                label: 'Trabaja con nosotros'
              });
              
            
        }else{
            console.log("You must confirm you are not a robot");
        }

          
    } catch (ex) {
      message.error(ex.message)
      ReactGA.event({
        category: 'work with us',
        action: 'Error al enviar formulario',
        label: 'Trabaja con nosotros'
      });
    } finally {
      this.setState({loader: false})
    }
  }

  render() {

   
    const {name, email, phone, dni, position, cv, cvname} = this.state

    return (
      <Master
        tag='works'
        // tag='logixtal'
        title='¿Quieres ser parte de la familia de LOGIXTAL?'
        description='Postula a un puesto de tu interés y trabaja con nosotros.'
        image={require('../assets/image/home/Historia.jpg')}
        contentParallax={(
          <div className='content-swipe' style={{zIndex: 9}}>
            <div className="io-form-group">
              <span>Nombre y Apellido</span>
              <input type="text" name="name" placeholder="Nombre y Apellido" value={name}
                     onChange={this.onChangeHandler}/>
            </div>
            <div className="io-grid grid-md-2">
              <div className="io-form-group">
                <span>DNI:</span>
                <input type="number" name="dni" placeholder="DNI" value={dni} onChange={this.onChangeHandler}/>
              </div>
              <div className="io-form-group">
                <span>Celular:</span>
                <input type="number" name="phone" placeholder="Celular" value={phone} onChange={this.onChangeHandler}/>
              </div>
            </div>
            <div className="io-grid grid-md-2">
              <div className="io-form-group">
                <span>Correo electrónico:</span>
                <input type="email" name="email" placeholder="Correo electrónico" value={email}
                       onChange={this.onChangeHandler}/>
              </div>
              <div className="io-form-group">
                <span>&nbsp;</span>
                <input type="file" name="cv" style={{"display": "none"}} onChange={this.onFileSelected}/>
                <div className="io-btn small" onClick={this.onUploadFile}><UploadOutlined/>&nbsp;&nbsp;Adjuntar CV</div>
              </div>
            </div>
            <div className="io-form-group">
              <span>Puesto de Interés:</span>
              <textarea placeholder="Puesto de Interés..." name="position" value={position} onChange={this.onChangeHandler}/>
            </div>

            <div className="io-form-group">
              <div style={{marginBottom: 20}}>
                <Checkbox name="checkTerminos"  value={this.state.verfied}  onChange={this.onPrivacyChange} >Acepto la <Link style={{color: '#45e5fb'}} target='_blank' to="/politicas-de-privacidad">Política de Privacidad</Link></Checkbox>
              </div>
            </div>

            <div className="io-grid grid-md-2">

            <ReCAPTCHA 
              sitekey={process.env.REACT_APP_SITE_KEY} 
              onChange={this.onChange}
              ref={captchaRef}  />

              <div   className={(this.state.verfiedRecaptcha) ? "io-btn" : "io-btn desability"} onClick={this.submitHandler} disabled={true} >Enviar</div>

            </div>


           
            {/* <ReCaptcha
              ref={ref => this.recaptcha = ref}
              sitekey={key_website}
              action='action_name'
              verifyCallback={this.verifyCallback}
            /> */}

          </div>
        )}
      >
        {this.state.loader && <Loader message='Enviando datos'/>}
      </Master>
    )
  }
}

const LineForm = (props) => (
  <div className="io-form-group">
    <span>{props.label}</span>
    {props.textarea ?
      (<textarea placeholder={props.placeholder || props.label}/>)
      :
      (<input type={props.type || 'text'} placeholder={props.placeholder || props.label}/>)
    }
  </div>
)











