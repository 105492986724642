import React from "react";

export const CardService = (props) => (
  <div
    className={props.min ?  "io-card modern min" : "io-card modern"}
    onMouseOver={(e) => blur(e, true)}
    onMouseOut={(e) => blur(e, false)}
  >
    <div className="image">
      <img src={props.image} alt="bg"/>
    </div>
    <div className="info">
      <p className="title">{props.title}</p>
      <div className="description">
        <p>{props.description}</p>
      </div>
    </div>
  </div>
)

function blur(e, t) {
  let content = document.querySelector('.list-services');
  if(t){
    content.classList.add('active')
  } else {
    content.classList.remove('active')
  }
}