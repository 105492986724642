import React from 'react';
import {loadReCaptcha} from 'react-recaptcha-v3'
import ReactGA from 'react-ga';
import 'antd/dist/antd.css';
import './io/css/io.css';
import AppInside from "./AppInside";
import {Loader} from "./components/display/Loader";
import {message} from "antd";

//const key_website = '6LdXFcwZAAAAAL1gctMETbz4Uw3YJvhKB4qwQzBC';
const key_website = '';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: false,
      loader: true,
      cookies: false,
      expandCookies: false
    }
  }

  componentDidMount() {
    // ReactGA.initialize('UA-177922504-1');
    ReactGA.initialize('UA-179276633-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    loadReCaptcha(key_website, this.isValidesd());
    setTimeout(() => {
      this.setState({loader: false})
    }, 2000)
    if (!localStorage.cookies) {
      this.setState({cookies: true})
    }
  }

  isValidesd = () => {
    console.log('loaded captcha')
  }

  acceptCookies = () => {
    this.setState({cookies: false});
    localStorage.cookies = true;
    message.success('Gracias por confiar en nosotros')
    ReactGA.event({
      category: 'button',
      action: 'Uso de cookies ACEPTADO',
      label: 'Cookies'
    });
  }

  rejectCookies = () => {
    this.setState({cookies: false})
    localStorage.cookies = false;
    message.warning('cookies rechazado')
    ReactGA.event({
      category: 'button',
      action: 'Uso de cookies RECHAZADO',
      label: 'Cookies'
    });
  }

  expandCookies = () => {
    this.setState({expandCookies: true})
  }

  render() {
    return (
      <>
        <AppInside/>
        {this.state.cookies &&
        <Cookies
          onAcept={this.acceptCookies}
          onReject={this.rejectCookies}
          onExpand={this.expandCookies}
          readMore={this.state.expandCookies}/>
        }
        {this.state.loader && <Loader/>}
      </>
    )
  }
}

export default App;

const Cookies = (props) => (
  <div className='io-cookies'>
    <div className='left'>
      <p>Esta página web usa cookies</p>
      {props.readMore ? (
        <p>En cumplimiento con lo dispuesto en la Ley 29733 – Ley de Protección de Datos Personales, y su Reglamento
          Aprobado mediante Decreto Supremo 003-2013-JUS esta página web le informa, en esta sección, sobre la política
          de
          recogida y tratamiento de cookies. Las cookies de este sitio web se usan para personalizar el contenido y los
          anuncios, ofrecer funciones de redes sociales y analizar el tráfico.</p>
      ) : (
        <p>En cumplimiento con lo dispuesto en la Ley 29733 – Ley de Protección de Datos Personales, y su Reglamento
          Aprobado mediante Decreto Supremo 003-2013-JUS <strong onClick={props.onExpand} style={{
            whiteSpace: "nowrap",
            color: "var(--Primary)"
          }}>Leer más</strong></p>
      )}
    </div>
    <div className='right'>
      <div className="io-btn small primary" onClick={props.onAcept}>Aceptar</div>
      <div className="io-btn small" onClick={props.onReject}>Rechazar</div>
    </div>
  </div>
)