import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import ReactGA from "react-ga";
import {
  IconBpo,
  IconFeature1,
  IconFeature2,
  IconFeature3,
  IconFeature4,
  IconFeature5,
  IconLogitrack,
  IconMadit,
  IconMedixtal,
  IconVeridomi,
} from "./icons";
// import {} from "./icons";

export const isTablet = window.innerWidth >= 768;

export const swipeScroll = (t) => {
  let display = document.querySelector(t);
  display.scrollTop > 10
    ? display.classList.add("scroll")
    : display.classList.remove("scroll");
};

export const Analytics = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const Icon = (props) => {
  let icon;
  switch (props.icon) {
    //Start icons local

    case "io-setting": {
      icon = <SettingOutlined {...props} />;
      break;
    }

    case "io-bpo-fav": {
      icon = <IconBpo {...props} />;
      break;
    }
    case "io-madit-fav": {
      icon = <IconMadit {...props} />;
      break;
    }
    case "io-logitrack-fav": {
      icon = <IconLogitrack {...props} />;
      break;
    }
    case "io-veridomi-fav": {
      icon = <IconVeridomi {...props} />;
      break;
    }
    case "io-medixtal-fav": {
      icon = <IconMedixtal {...props} />;
      break;
    }
    case "io-feature-1": {
      icon = <IconFeature1 {...props} />;
      break;
    }
    case "io-feature-2": {
      icon = <IconFeature2 {...props} />;
      break;
    }
    case "io-feature-3": {
      icon = <IconFeature3 {...props} />;
      break;
    }
    case "io-feature-4": {
      icon = <IconFeature4 {...props} />;
      break;
    }
    case "io-feature-5": {
      icon = <IconFeature5 {...props} />;
      break;
    }
    //End icons local

    default: {
      icon = <SettingOutlined {...props} />;
      break;
    }
  }
  return icon;
};
