import React from "react";
import {motion} from "framer-motion"
import {Header} from "../layout/Header";
import {Link} from "react-router-dom";
import {Analytics} from "../../io/io";
import {message} from "antd";
import {Contact} from "../controllers/contact";
import ReactGA from "react-ga";

class Parallax extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demo: false,
      loader: false,
      name: '',
      email: '',
      company: '',
      phone: '',
      ruc: '',
      message: ''
    }
  }

  demo = (s) => {
    console.log(s)
    this.setState({demo: true})
    Analytics('Solicitud demo', s, 'Servicio ' + s)
  }

  closeDemo = () => {
    this.setState({demo: false})
  }

  onChangeHandler = (e) => {
    this.setState({[e.target.name]: e.target.value})
    console.log(e.target.value)
  }

  submitHandler = async () => {
    try {
      if (!/^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,3}$/.test(this.state.name))
        throw {message: "Ingrese un nombre válido. (Máximo 2 nombres y 2 apellidos, sin carecteres especiales ni acentos)"}
      if (!/^[0-9]{9}$/.test(this.state.phone))
        throw {message: "Ingrese un teléfono válido. (números sin espacios de 9 cifras)"}
      if (!/^[0-9]{11}$/.test(this.state.ruc))
        throw {message: "Ingrese un RUC válido."}
      if (!/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(this.state.email))
        throw {message: "Ingrese un email válido. (example@domain.com)"}
      this.setState({loader: true})
      let contact = new Contact()
      let response = await contact.add(this.state)
      if (!response.status)
        throw {message: response.message}
      message.success('Solicitud enviado con exito, en breve nos pondremos en contacto.')
      this.closeDemo()

    } catch (ex) {
      message.error(ex.message)
    } finally {
      this.setState({loader: false})
    }
  }

  render() {
    const {name, email, company, phone, ruc, message} = this.state
    return (
      <>
        <section className={"home " + this.props.tag || ''}>
          {this.props.notHead ? null : <Header {...this.props}/>}
          <div className="io-content-swipe">
            <div className="info">
              {this.props.logo ? (
                <p className="title">{this.props.title}</p>
              ) : this.props.service
                ? (
                  <div className='io-service-tag'>
                    <div className="tag">{this.props.type || 'Servicio'}</div>
                    <p className="title">{this.props.title}</p>
                  </div>
                ) :
                <p className="title">{this.props.title}</p>
              }
              {this.props.descriptionChange || <p className="description">{this.props.description}</p>}
              {this.props.activeButtons ? (
                <div className="io-row" style={{marginTop: 40}}>
                  {this.props.app && (
                    <>
                      <a target={'_blank'}
                         href={'https://play.google.com/store/apps/details?id=com.logixtal.madit&hl=es&showAllReviews=true'}
                         className="io-btn">
                        <svg className="left" width="21" height="23" viewBox="0 0 21 23" fill="none">
                          <path
                            d="M0.0289911 19.619C0.0289911 20.2836 0.306741 20.8914 0.664241 21.2287C0.988741 21.6742 1.75141 22.0253 2.42332 22.0253C2.82024 22.0253 3.22082 21.9263 3.58841 21.7228L14.5215 15.6123C14.5389 15.6022 14.5472 15.5857 14.5627 15.5747C15.1916 15.2969 16.3722 14.5572 18.9637 13.129H18.9646C20.6036 12.2096 20.6054 9.84277 18.9637 8.92152C16.5345 7.68127 15.3538 6.82602 14.6517 6.49877C13.9174 5.9781 12.0575 5.04402 8.01041 2.77527C2.87249 -0.103065 2.07682 -0.598065 0.841158 0.627519C-0.460509 1.1656 0.177491 4.76443 0.0289911 19.619ZM9.44958 11.0042C9.35699 11.0949 1.49016 19.5063 1.40399 19.5979C1.53874 6.11193 1.28024 4.17044 1.41224 2.46085C1.49749 2.55252 9.13974 10.7072 9.44958 11.0042ZM2.30782 20.6439C12.8889 9.53293 9.94733 12.6267 10.4579 12.0593C10.545 12.1519 13.2547 15.0265 13.0814 14.8432C2.61308 20.6145 2.91741 20.7154 2.30782 20.6439ZM18.2917 11.9291L14.3189 14.1511L11.3727 11.0253L14.3189 7.89944L18.2917 10.1214C18.9957 10.5147 18.9948 11.5359 18.2917 11.9291ZM13.0787 7.2101C11.9337 8.41277 12.9467 7.37602 10.4148 10.0078L2.30599 1.40394C3.22357 1.48552 6.10374 3.34452 13.0787 7.2101Z"
                            fill="currentColor"/>
                        </svg>
                        Google Play
                      </a>
                      <div className="io-space"/>
                    </>
                  )}
                  {this.props.demo && (
                    <div className="io-btn" onClick={() => this.demo(this.props.tag || '')}>Solicitar Demo</div>)}
                  {this.props.see && (this.props.home ? <Link to='/medixtal' className="io-btn">Vér Catalogo</Link> :
                    <a href='#product' className="io-btn">Ver Catálogo</a>)}
                </div>
              ) : null}
            </div>
            {this.props.contentParallax}
          </div>
          <div className="bg"/>
          {this.props.image && <img className='image' src={this.props.image} alt="bg"/>}
        </section>

        {this.state.demo && (
          <FormDemo
            tag={this.props.tag}
            name={name}
            email={email}
            company={company}
            phone={phone}
            ruc={ruc}
            message={message}
            onChangeHandler={this.onChangeHandler}
            submitHandler={this.submitHandler}
            close={this.closeDemo}
          />
        )}
      </>
    )
  }
}

export default Parallax

const FormDemo = (props) => (
  <div className='io-drag'>
    <motion.div
      className="mask"
      onClick={props.close}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20
      }}
    />
    <motion.div
      className="content"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <div className="top">
        <p style={{fontWeight: 'bold'}}>Solicitar demo {props.tag}</p>
        <div className="close" onClick={props.close}>
          <svg viewBox="0 0 352 512">
            <g className="fa-group">
              <path fill="currentColor"
                    d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
                    opacity=".6"/>
              <path fill="currentColor"
                    d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"/>
            </g>
          </svg>
        </div>
      </div>

      <Item>
        <span>Nombre completo:</span>
        <input type="text" name="name" placeholder="Nombre completo" value={props.name}
               onChange={props.onChangeHandler}/>
      </Item>
      <div className="io-grid grid-md-2">
        <Item className="io-form-group">
          <span>Celular:</span>
          <input type="number" name="phone" placeholder="Celular" value={props.phone} onChange={props.onChangeHandler}/>
        </Item>
        <Item className="io-form-group">
          <span>Correo electrónico:</span>
          <input type="email" name="email" placeholder="Correo electrónico" value={props.email}
                 onChange={props.onChangeHandler}/>
        </Item>
      </div>
      <div className="io-grid grid-md-2">
        <Item className="io-form-group">
          <span>Nombre de la empresa:</span>
          <input type="text" name="company" placeholder="Nombre de la empresa" value={props.company}
                 onChange={props.onChangeHandler}/>
        </Item>
        <Item className="io-form-group">
          <span>RUC Empresa:</span>
          <input type="number" name="ruc" placeholder="RUC Empresa" value={props.ruc} onChange={props.onChangeHandler}/>
        </Item>
      </div>
      <div className="io-grid grid-md-2">
        <div/>
        <div className="io-btn primary small" onClick={props.submitHandler}>Enviar</div>
      </div>
    </motion.div>
  </div>
)

const Item =(props)=>(
  <motion.div className="io-form-group" variants={item}>
    {props.children}
  </motion.div>
)

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};