import React from "react";
import { CardInfo } from "../components/cards/Info";
import CardProject from "../components/cards/Project";
import { CardService } from "../components/cards/Service";
import { CarouselClient, Client } from "../components/display/carousel";
import { Section } from "../components/display/section";
import { Master } from "../components/layout/Master";

export function Category1(props) {
  return (
    <Master
      title="Distribución de valorados"
      description="En Logixtal nos especializamos en la distribución de productos valorados los cuales requieren una entrega personalizada, segura y rápida. Para ello, nuestro equipo se soporta mediante la tecnología de punta para el control exhaustivo que sus productos necesitan y el seguimiento en tiempo real es lo que se aplica."
      image={require("../assets/image/read/1.jpg")}
    >
      <Section light title="Distribución de tarjetas de crédito">
        <p>
          El servicio se realiza siguiendo los protocolos diseñados por las
          entidades financieras y la Superintendencia de Banca y Seguros y AFP
          (SBS). Incluye recojo de valijas de tarjetas del almacén cliente,
          almacenamiento seguro en el nuestro, Call Center inbound y outbound de
          coordinación, distribución y validación de entregas. Para rezagos,
          proceso de destrucción.
        </p>
        <br />
        <b>Características</b>
        <br />
        <br />
        <div className="list-services">
          <CardInfo description="Se utiliza tecnología de última generación para personalizar nuestro sistema a los procesos de nuestros clientes." />
          <CardInfo description="Reportes de Acuerdo de Servicio en línea y generación automática de informes para el cliente." />
          <CardInfo description="Se hace seguimiento mediante GPS a todos nuestros mensajeros." />
          <CardInfo description="Se realiza cuádruple validación de entrega: Mensajero, Coordinador, Supervisor y Documentador." />
          <CardInfo description="Validación biométrica de huellas dactilares en línea directa con el sistema de RENIEC." />
        </div>
      </Section>
      <Section title="Distribución de Teléfonos celulares">
        <p>
          Logixtal se especializa por tener el know how y aplicar el E2E de toda
          la cadena de suministro hasta la última milla en el servicio de las
          Telecomunicaciones manteniendo el almacén, inventario, logística
          inversa, servicio de delivery, cobranza de dinero, activación de
          líneas telefónicas y revisión de contratos. Todo servicio se maneja
          bajo los estándares de nuestros clientes.
        </p>
        <br />
        <b>Características</b>
        <br />
        <br />
        <div className="list-services">
          <CardInfo description="Control y seguimiento continuo mediante plataforma propia y GPS." />
          <CardInfo description="Estricto cumplimiento de los rangos horarios." />
          <CardInfo description="Entregas express con gran cobertura." />
          <CardInfo description="Call Center para confirmaciones ante el cliente." />
          <CardInfo description="Omnicanalidad para un mejor contacto con el cliente." />
          <CardInfo description="Motorizados propios, no tercerizamos." />
          <CardInfo description="Aplicación de biometría." />
          <CardInfo description="Cobranza en efectivo y MPOS." />
          <CardInfo description="Aplicación de mystery shopper para medir la calidad del servicio." />
          <CardInfo description="Plataforma interactiva para el seguimiento de los canales de ventas." />
        </div>
      </Section>
      {/* <Section title="Aplicaciones y complementos" light>
        <div className="list-services">
          {data.map(
            (card, index) =>
              card.type === "service" && (
                <CardProject
                  key={index}
                  image={card.image}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  route={card.route}
                />
              )
          )}
        </div>
      </Section> */}
    </Master>
  );
}
const data = [
  {
    image: require("../assets/image/madit/1-Madit.jpg"),
    icon: "io-bpo-fav",
    title: "BPO",
    description: "Externalización de Procesos de Negocios",
    route: "/bpo",
    type: "service",
  },
  {
    image: require("../assets/image/madit/5-BiometriaFacialMadit.jpg"),
    icon: "io-madit-fav",
    title: "MADIT",
    description: "Sistema de validación biométrica y dactilar",
    route: "/madit",
    type: "service",
  },
  {
    image: require("../assets/image/logitrack/1-LogiTrack.jpg"),
    icon: "io-logitrack-fav",
    title: "LOGITRACK",
    description: "Plataforma de Delivery Modular, Integrada e Inteligente",
    route: "/logitrack",
    type: "service",
  },
  // {
  //   image: require("../assets/image/home/home-veridomi.jpg"),
  //   icon: "io-veridomi-fav",
  //   title: "VERIDOMI",
  //   description: "Verificaciones Domiciliarias y Laborales",
  //   route: "/veridomi",
  //   type: "service",
  // },
  {
    image: require("../assets/image/medixtal/medixtal.jpg"),
    icon: "io-medixtal-fav",
    title: "MEDIXTAL",
    description: "Línea médica de Logixtal",
    route: "/medixtal",
    type: "product",
  },
  {
    image:
      "https://images.pexels.com/photos/3944405/pexels-photo-3944405.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Entrega de tarjetas de crédito y débito",
    description:
      "Brindamos condiciones de seguridad desde la recepción del valorado hasta la entrega al destinatario",
    route: "/entrega-tarjeta-credito-debito",
    type: "category",
  },
  // {
  //   image:
  //     "https://images.pexels.com/photos/7218511/pexels-photo-7218511.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
  //   title: "Verificaciones domiciliarias y laborales",
  //   description:
  //     "Confirmamos los datos proporcionados por terceros, a través de la visita física al lugar de domicilio o trabajo de personas naturales.",
  //   route: "/verificacion-domiciliaria-laboral",
  //   type: "category",
  // },
  {
    image:
      "https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Recojo y envío de documentación",
    description:
      "Colocamos a disposición de su negocio nuestro currier de envíos de documentos y valorados. Contamos con plan exclusivo para tiendas online. Repartos programados",
    route: "/recojo-envio-documentacion",
    type: "category",
  },
  {
    image:
      "https://images.pexels.com/photos/4393432/pexels-photo-4393432.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Logística y entrega de paquetería",
    description:
      "Somos el nexo o puente entre los productos de una empresa y los clientes finales.",
    route: "/entrega-paqueteria",
    type: "category",
  },
  {
    image:
      "https://images.pexels.com/photos/4386321/pexels-photo-4386321.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Recaudación y cobranzas",
    description:
      "Mensualmente generamos una recaudación de más de 3 millones de soles para nuestros clientes.",
    route: "/recaudacion-cobranzas",
    type: "category",
  },
];
