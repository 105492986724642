import React from "react";
import { Link } from "react-router-dom";

class CardCategory extends React.Component {
  render() {
    return (
      <Link
        to={this.props.route}
        className={this.props.min ?"io-card category min" : "io-card category"}
        onClick={this.props.onClick}
      >
        <div className="image">
          <img src={this.props.image} />
        </div>
        <div>
          <h2>{this.props.title}</h2>
          {/* <p>{this.props.description}</p> */}
        </div>
      </Link>
    );
  }
}
export default CardCategory;
