import React from "react";
import CardProject from "../components/cards/Project";
import { CarouselClient, Client } from "../components/display/carousel";
import { Section } from "../components/display/section";
import { Master } from "../components/layout/Master";

export function Category3(props) {
  return (
    <Master
      title="Recojo y envío de documentación"
      description="El servicio de recojo y envío de documentación, esto incluye impresión, validación y recolección de documentos para su posterior devolución."
      image="https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
    >
      <Section light>
        El servicio de recojo y envío de documentación, esto incluye impresión,
        validación y recolección de documentos para su posterior devolución.
        <br />
        <br />
        El cliente nos envía su solicitud mediante nuestra web, de acuerdo lo
        solicitado se asigna a un mensajero para recoger la documentación desde
        sus oficinas o a la dirección que nos indique o también pueden enviarnos
        la documentación de manera digital, de modo que nosotros nos encargamos
        de imprimirla y enviarla.
        <br />
        <br />
        Luego se procede con la recolección de firmas y se entrega en la
        dirección indicada o se almacena en nuestras oficinas para posterior
        validación. Contamos con un área de mesa de control que valida firmas y
        el correcto llenado de los documentos.
      </Section>

      {/* <Section title="Aplicaciones y complementos" light>
        <div className="list-services">
          {data.map(
            (card, index) =>
              card.type === "service" && (
                <CardProject
                  key={index}
                  image={card.image}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  route={card.route}
                />
              )
          )}
        </div>
      </Section> */}
    </Master>
  );
}
const data = [
  {
    image: require("../assets/image/madit/1-Madit.jpg"),
    icon: "io-bpo-fav",
    title: "BPO",
    description: "Externalización de Procesos de Negocios",
    route: "/bpo",
    type: "service",
  },
  {
    image: require("../assets/image/madit/5-BiometriaFacialMadit.jpg"),
    icon: "io-madit-fav",
    title: "MADIT",
    description: "Sistema de validación biométrica y dactilar",
    route: "/madit",
    type: "service",
  },
  {
    image: require("../assets/image/logitrack/1-LogiTrack.jpg"),
    icon: "io-logitrack-fav",
    title: "LOGITRACK",
    description: "Plataforma de Delivery Modular, Integrada e Inteligente",
    route: "/logitrack",
    type: "service",
  },
  // {
  //   image: require("../assets/image/home/home-veridomi.jpg"),
  //   icon: "io-veridomi-fav",
  //   title: "VERIDOMI",
  //   description: "Verificaciones Domiciliarias y Laborales",
  //   route: "/veridomi",
  //   type: "service",
  // },
  {
    image: require("../assets/image/medixtal/medixtal.jpg"),
    icon: "io-medixtal-fav",
    title: "MEDIXTAL",
    description: "Línea médica de Logixtal",
    route: "/medixtal",
    type: "product",
  },
  {
    image:
      "https://images.pexels.com/photos/3944405/pexels-photo-3944405.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Entrega de tarjetas de crédito y débito",
    description:
      "Brindamos condiciones de seguridad desde la recepción del valorado hasta la entrega al destinatario",
    route: "/entrega-tarjeta-credito-debito",
    type: "category",
  },
  // {
  //   image:
  //     "https://images.pexels.com/photos/7218511/pexels-photo-7218511.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
  //   title: "Verificaciones domiciliarias y laborales",
  //   description:
  //     "Confirmamos los datos proporcionados por terceros, a través de la visita física al lugar de domicilio o trabajo de personas naturales.",
  //   route: "/verificacion-domiciliaria-laboral",
  //   type: "category",
  // },
  {
    image:
      "https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Recojo y envío de documentación",
    description:
      "Colocamos a disposición de su negocio nuestro currier de envíos de documentos y valorados. Contamos con plan exclusivo para tiendas online. Repartos programados",
    route: "/recojo-envio-documentacion",
    type: "category",
  },
  {
    image:
      "https://images.pexels.com/photos/4393432/pexels-photo-4393432.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Logística y entrega de paquetería",
    description:
      "Somos el nexo o puente entre los productos de una empresa y los clientes finales.",
    route: "/entrega-paqueteria",
    type: "category",
  },
  {
    image:
      "https://images.pexels.com/photos/4386321/pexels-photo-4386321.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Recaudación y cobranzas",
    description:
      "Mensualmente generamos una recaudación de más de 3 millones de soles para nuestros clientes.",
    route: "/recaudacion-cobranzas",
    type: "category",
  },
];
