import React from "react";
import { CardInfo } from "../components/cards/Info";
import CardProject from "../components/cards/Project";
import { CardService } from "../components/cards/Service";
import { CarouselClient, Client } from "../components/display/carousel";
import { Section } from "../components/display/section";
import { Master } from "../components/layout/Master";

export function Category4(props) {
  return (
    <Master
      title="Logística y transporte para Ecommerce"
      description="Se realiza la distribución y entrega de paquetes de pequeño y mediano tamaño. Incluye recojo, almacenaje, cobranza y entrega. A través de nuestro sistema el cliente carga su solicitud, con estos parámetros un motorizado es enviado a realizar el recojo ya sea desde nuestros almacenes o de cualquier punto de recojo para su posterior entrega, todo esto puede ser monitoreado en tiempo real."
      image={require("../assets/image/read/3.jpg")}
    >
      <Section light>
        Contamos con almacenes para los productos a entregar, nuestros almacenes
        se encuentran ubicados en las zonas de mayor demanda de Lima
        Metropolitana, Callao y provincias. Esto contribuye a mejorar la
        efectividad de entrega. (Mejor recorrido de motorizados y camiones de
        reparto por zonas)
        <br />
        <br />
        El Almacén cuenta con seguridad biométrica, cámaras y seguro contra
        robos, dentro de edificio corporativo y con puertas blindadas. Mediante
        el desarrollo de nuestro software registramos correctamente las
        transacciones de ingreso y salida de mercadería, así como las
        ubicaciones
        <br />
        Contamos con software de múltiples almacenes que permite registrar
        correctamente las transacciones de ingreso y salida de mercadería, así
        como las ubicaciones físicas de los productos en anaqueles debidamente
        identificados y con buena accesibilidad para el manejo de números de
        serie en los productos.
      </Section>
      <Section title="Características">
        <div className="list-services">
          <CardInfo description="Nuestros sistemas permiten gestionar las rutas de los motorizados integrando el seguimiento con celulares tipo smartphones de cada uno de ellos. De esta manera, protegemos toda la información privada o sensible del cliente." />
          <CardInfo description="Todos nuestros envíos a clientes salen de base con el comprobante electrónico correspondiente (Guía de Remisión o Guía de Traslado) consignando los datos del producto transportado." />
          <CardInfo description="El estado de la entrega se actualizará de manera inmediata para poder obtener un adecuada reportería con indicadores de tiempo y efectividad." />
          <CardInfo description="Previo a la entrega del producto al cliente, además de la verificación del documento de identidad, el motorizado deberá realizar una validación biométrica dactilar desde su dispositivo móvil para identificar al cliente y evitar fraudes en el proceso." />
          <CardInfo description="Coordinación con clientes para mejorar la efectividad de la operación." />
          <CardInfo description="Reprogramaciones de pedidos no entregados" />
          <CardInfo description="Entrega de pedidos express, en el mismo día que se realiza la venta." />
          <CardInfo description="El motorizado deberá solicitar la firma de los cargos que son solicitados por el canal de ventas al Courier." />
          <CardInfo description="Toma de fotos al cliente, DNI y cualquier otro documento que esté cargado en nuestro sistema para futuros fraudes." />
          <CardInfo description=" Asignación y cobertura de rutas por distritos en Lima, Callao y provincias todos los días incluyendo domingos y algunos feriados." />
        </div>
      </Section>
      <Section title="Servicios" light>
        <div className="list-services">
          <CardService
            image={require("./../assets/image/logitrack/2-e-commerce.jpg")}
            title="INTEGRACIÓN CON E-COMMERCE"
            description="Contamos con un equipo in-house de desarrolladores y programadores que pueden integrarnos a las necesidades exactas del e-commerce. Desde ventanas horarias a discreción del cliente, tipificación de entregas, clientes VIP hasta integración con OMS & WMS para evitar órdenes de entrega “fantasmas” por falta de inventario o automatizar direcciones de recojo según cruce de inventario. Tú lo necesitas, nosotros lo hacemos"
          />
          <CardService
            image={require("./../assets/image/logitrack/3-HubDistribucion.jpg")}
            title="HUB DE DISTRIBUCIÓN"
            description="Contamos con un almacén amplio con los más altos estándares de calidad, preparado para paquetería pequeña, mediana y grande. Dentro de nuestras soluciones contamos con almacenamiento y resguardo de inventario de mayor rotación o mayor valor, cross-docking o cualquier solución que requiera un traslado o entrega por Delivery personalizado y en el menor tiempo posible."
          />
          <CardService
            image={require("./../assets/image/logitrack/4-Reempaquetado.jpg")}
            title="RE-EMPAQUETADO"
            description="  ¿Representas muchas marcas pero quieres reforzar la imagen de marca que como tienda tienes? Basta de empaques
            distintos para la entrega. Podemos recoger los productos, hacer el proceso de sorteado para generar las
            entregas y consolidar paquetes para que tus clientes reciban el menor número de cajas sueltas: todas con tu
            logo y el mensaje que quieras transmitir."
          />
          <CardService
            image={require("./../assets/image/logitrack/6-ContactoconCliente.jpg")}
            title="CONTACTO CON CLIENTE"
            description="Desde que se genera el pedido por el e-commerce, el cliente es parte vital y esencial del proceso de compra
            futura. Somos conscientes de ello y por eso lo mantenemos informado en los hitos más importantes posterior a
            la compra: confirmamos el día en el que se realizará la compra y, una vez activada la entrega de su producto,
            podrá monitorear en tiempo real el estado de su pedido por SMS o whatsapp."
          />
          <CardService
            image={require("./../assets/image/logitrack/7-EntregasVerificadasconReniec.jpg")}
            title="ENTREGAS VERIFICADAS"
            description="Podemos realizar entregas certificadas que requieran verificación y validación de la persona que recibe el producto."
          />
          <CardService
            image={require("./../assets/image/logitrack/8-ValidaciondeEntrega.jpg")}
            title="VALIDACIÓN DE ENTREGA"
            description="Todos nuestros repartidores son capacitados de forma constante. Además, cuentan con soporte directo y un aplicativo propio en el que deberán cargar evidencia de las entregas, lo que va desde fotos del domicilio declarado como punto de entrega, fotografía del DNI de la persona que recibe el producto, fotografía del usuario o cliente que recibe, entre otros puntos de control. Todo para brindar la mayor seguridad posible a tu negocio y tus clientes."
          />
        </div>
      </Section>
      {/* <Section title="Aplicaciones y complementos" light>
        <div className="list-services">
          {data.map(
            (card, index) =>
              card.type === "service" && (
                <CardProject
                  key={index}
                  image={card.image}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  route={card.route}
                />
              )
          )}
        </div>
      </Section> */}
    </Master>
  );
}
const data = [
  {
    image: require("../assets/image/madit/1-Madit.jpg"),
    icon: "io-bpo-fav",
    title: "BPO",
    description: "Externalización de Procesos de Negocios",
    route: "/bpo",
    type: "service",
  },
  {
    image: require("../assets/image/madit/5-BiometriaFacialMadit.jpg"),
    icon: "io-madit-fav",
    title: "MADIT",
    description: "Sistema de validación biométrica y dactilar",
    route: "/madit",
    type: "service",
  },
  {
    image: require("../assets/image/logitrack/1-LogiTrack.jpg"),
    icon: "io-logitrack-fav",
    title: "LOGITRACK",
    description: "Plataforma de Delivery Modular, Integrada e Inteligente",
    route: "/logitrack",
    type: "service",
  },
  // {
  //   image: require("../assets/image/home/home-veridomi.jpg"),
  //   icon: "io-veridomi-fav",
  //   title: "VERIDOMI",
  //   description: "Verificaciones Domiciliarias y Laborales",
  //   route: "/veridomi",
  //   type: "service",
  // },
  {
    image: require("../assets/image/medixtal/medixtal.jpg"),
    icon: "io-medixtal-fav",
    title: "MEDIXTAL",
    description: "Línea médica de Logixtal",
    route: "/medixtal",
    type: "product",
  },
  {
    image:
      "https://images.pexels.com/photos/3944405/pexels-photo-3944405.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Entrega de tarjetas de crédito y débito",
    description:
      "Brindamos condiciones de seguridad desde la recepción del valorado hasta la entrega al destinatario",
    route: "/entrega-tarjeta-credito-debito",
    type: "category",
  },
  // {
  //   image:
  //     "https://images.pexels.com/photos/7218511/pexels-photo-7218511.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
  //   title: "Verificaciones domiciliarias y laborales",
  //   description:
  //     "Confirmamos los datos proporcionados por terceros, a través de la visita física al lugar de domicilio o trabajo de personas naturales.",
  //   route: "/verificacion-domiciliaria-laboral",
  //   type: "category",
  // },
  {
    image:
      "https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Recojo y envío de documentación",
    description:
      "Colocamos a disposición de su negocio nuestro currier de envíos de documentos y valorados. Contamos con plan exclusivo para tiendas online. Repartos programados",
    route: "/recojo-envio-documentacion",
    type: "category",
  },
  {
    image:
      "https://images.pexels.com/photos/4393432/pexels-photo-4393432.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Logística y entrega de paquetería",
    description:
      "Somos el nexo o puente entre los productos de una empresa y los clientes finales.",
    route: "/entrega-paqueteria",
    type: "category",
  },
  {
    image:
      "https://images.pexels.com/photos/4386321/pexels-photo-4386321.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "Recaudación y cobranzas",
    description:
      "Mensualmente generamos una recaudación de más de 3 millones de soles para nuestros clientes.",
    route: "/recaudacion-cobranzas",
    type: "category",
  },
];
