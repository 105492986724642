import React from "react";
import {CarouselClient, Client} from "../components/display/carousel";
import {Section} from "../components/display/section";
import {Master} from "../components/layout/Master";

export class Veridomi extends React.Component {
  render() {
    return (
      <Master
        tag='veridomi'
        title='Sistema de verificación online'
        description='VeriDomi es tu aliado estratégico al momento de realizar validaciones personales de forma 100% online'
        activeButtons
        demo
        image={require('./../assets/image/home/home-veridomi.jpg')}
        logo={(<Logo/>)}
      >
        <Section>
          VeriDomi es una plataforma que brinda soporte en el proceso de selección y evaluación del Recurso Humano, mitigando el riesgo  en la contratación de personal. Contamos con la experiencia y el personal altamente calificado que te garantiza una investigación detallada. Brindamos servicios de Referencias Laborales, Verificaciones Domiciliaria, Verificaciones Laborales, entre otras. Toda solicitud se realiza a través de nuestro portal web y en un plazo de entre 24 a 48 horas para algunos servicios y hasta en 3 horas para verificaciones físicas en algunos distritos de Lima.
        </Section>
        <CarouselClient>
          <Client image={require('./../assets/image/veridomi/clients/Overall.png')}/>
          <Client image={require('./../assets/image/veridomi/clients/Perufarma.jfif')}/>
          <Client image={require('./../assets/image/veridomi/clients/SantanderConsumer.png')}/>
          <Client image={require('./../assets/image/veridomi/clients/BBVAConsumerFinances.jpg')}/>
          <Client image={require('./../assets/image/veridomi/clients/Fargoline.png')}/>
          <Client image={require('./../assets/image/veridomi/clients/Interseguro.png')}/>
          <Client image={require('./../assets/image/veridomi/clients/EurekaEmpresarial.png')}/>
          <Client image={require('./../assets/image/veridomi/clients/Sodexo.png')}/>
          <Client image={require('./../assets/image/veridomi/clients/Mapfre.jpg')}/>
          <Client image={require('./../assets/image/veridomi/clients/Adecco.png')}/>
       </CarouselClient>
      </Master>
    )
  }
}

const Logo = (props) => (
  <svg style={{marginTop: -16}} className="logo" width="178" height="60" viewBox="0 0 178 60" fill="none">
    <path
      d="M38.1714 60H3.31429C1.48571 60 0 58.5143 0 56.6857V21.8286C0 20 1.48571 18.5143 3.31429 18.5143H38.1714C40 18.5143 41.4857 20 41.4857 21.8286V56.7143C41.4571 58.5143 39.9714 60 38.1714 60ZM3.28571 21.2286C2.97143 21.2286 2.68571 21.4857 2.68571 21.8286V56.7143C2.68571 57.0286 2.94286 57.3143 3.28571 57.3143H38.1429C38.4571 57.3143 38.7429 57.0571 38.7429 56.7143V21.8286C38.7429 21.5143 38.4857 21.2286 38.1429 21.2286H3.28571Z"
      fill="#878786"/>
    <path d="M5.74292 24.7143L21.2001 52.7143L59.4572 0L20.8572 39.4L5.74292 24.7143Z" fill="#8CBE22"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M44.1714 52.7143L44.1765 52.6857H61.1142L61.8571 48.4857H49.4L50.6285 41.6286H61.8571L62.6 37.4286H51.3714L52.4 31.7143H64L64.7428 27.5143H48.6857L44.1765 52.6857H44.1714V52.7143ZM68.8572 52.7143H64.4L68.9143 27.5143H78.5429C80.3143 27.5143 81.6572 27.7429 82.5143 28.1714C83.4 28.6286 84.1143 29.3429 84.6572 30.3429C85.2 31.3429 85.4857 32.6 85.4857 34.1143C85.4857 36.3429 84.9143 38.1429 83.7714 39.4857C82.6286 40.8286 80.9715 41.6571 78.8286 41.9714C79.4286 42.6 80.0286 43.5143 80.6 44.6571C81.5715 46.6 82.6 49.2857 83.7714 52.6857H78.9715C78.7715 51.9714 78.1429 50.0571 77.0286 46.8857C76.4572 45.2571 75.9143 44.1429 75.4572 43.4571C75.1143 43 74.7429 42.6571 74.4 42.4857C74.0286 42.3143 73.4286 42.2286 72.5715 42.2286H70.7429L68.8572 52.7143ZM71.4286 38.4857H73.7714C76 38.4857 77.5143 38.3429 78.3143 38.0857C79.1143 37.8286 79.7429 37.3143 80.2857 36.6C80.8 35.8857 81.0572 35.0857 81.0572 34.2C81.0572 33.2571 80.7143 32.5429 80.0572 32.1143C79.6857 31.8286 78.8286 31.7143 77.5429 31.7143H72.6572L71.4286 38.4857ZM90.4001 27.5143L85.6858 52.7143H90.1429L94.8572 27.5143H90.4001Z"
          fill="#8CBE22"/>
    <path
      d="M97.3228 52.8571L101.77 27.2634L108.415 27.281C110.5 27.281 112.293 27.8025 113.793 28.8454C115.305 29.8884 116.389 31.3298 117.045 33.1696C117.702 34.9978 117.895 37.0485 117.625 39.322L117.45 40.6052C116.934 44.3318 115.487 47.3083 113.108 49.5349C110.729 51.7497 107.741 52.8571 104.143 52.8571H97.3228ZM104.583 30.0407L101.102 50.0974L104.354 50.115C106.838 50.115 108.942 49.3415 110.665 47.7946C112.399 46.2478 113.524 44.1267 114.04 41.4314C114.45 39.2751 114.59 37.4411 114.461 35.9294C114.286 34.113 113.682 32.695 112.651 31.6755C111.62 30.6443 110.237 30.1052 108.502 30.0583L104.583 30.0407Z"
      fill="#878786"/>
    <path
      d="M128.007 53.2087C126.378 53.1735 124.96 52.781 123.753 52.031C122.558 51.281 121.615 50.1794 120.923 48.7263C120.232 47.2614 119.851 45.6208 119.781 43.8044C119.71 42.3864 119.868 40.6345 120.255 38.5485C120.642 36.4626 121.31 34.5818 122.259 32.906C123.208 31.2302 124.363 29.8884 125.722 28.8806C127.562 27.5212 129.659 26.865 132.015 26.9118C134.511 26.9587 136.48 27.8259 137.921 29.5134C139.363 31.1892 140.136 33.4626 140.242 36.3337C140.288 37.5642 140.159 39.1286 139.855 41.0271C139.562 42.9255 139.029 44.6892 138.255 46.3181C137.493 47.947 136.521 49.3122 135.337 50.4138C133.275 52.3357 130.831 53.2673 128.007 53.2087ZM136.99 37.1423C137.06 34.8337 136.656 33.0466 135.777 31.781C134.898 30.5036 133.591 29.8415 131.857 29.7946C130.286 29.7478 128.88 30.1696 127.638 31.0603C126.408 31.9509 125.394 33.2868 124.597 35.0681C123.812 36.8376 123.314 39.0818 123.103 41.8005L123.05 42.9431C122.98 45.24 123.39 47.0388 124.281 48.3396C125.171 49.6403 126.466 50.3142 128.165 50.361C130.38 50.4196 132.214 49.6228 133.667 47.9704C135.132 46.3064 136.105 43.9743 136.585 40.9743C136.82 39.4978 136.954 38.2204 136.99 37.1423Z"
      fill="#878786"/>
    <path
      d="M149.621 27.2634L154.104 48.111L165.846 27.2634H170.188L165.74 52.8571H162.453L164.211 42.7321L166.303 32.4314L154.701 52.8571H152.17L147.6 32.0271L146.018 43.0661L144.313 52.8571H141.008L145.455 27.2634H149.621Z"
      fill="#878786"/>
    <path d="M173.397 52.8571H170.11L174.558 27.2634H177.845L173.397 52.8571Z" fill="#878786"/>
  </svg>
)

