import React from "react";
import {motion} from "framer-motion"

class TabsModern extends React.Component {
  render() {
    return (
      <div className='box-tabs'>
        <div className="io-tabs">
          {/*<Tab className={this.props.value === 1 ? 'tab active' : 'tab'} onClick={this.props.tabOne}>Historia</Tab>*/}
          <Tab className={this.props.value === 2 ? 'tab active' : 'tab'} onClick={this.props.tabTwo}>Propósito</Tab>
          <Tab className={this.props.value === 3 ? 'tab active' : 'tab'} onClick={this.props.tabThree}>Valores</Tab>
        </div>
      </div>
    )
  }
}

export default TabsModern

const Tab = (props) => (
  <motion.div
    {...props}
    whileHover={{scale: 1.1}}
    whileTap={{
      scale: 0.8,
      borderRadius: "20px"
    }}
  >
    {props.children}
  </motion.div>
)