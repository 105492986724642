import React from "react";

class CardHome extends React.Component {
  render() {
    return (
      <article className='io-card home'>
        <p className='title'>{this.props.title}</p>
        <p className='description'>{this.props.description}</p>
      </article>
    )
  }
}
export default CardHome