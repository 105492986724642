import React from "react";
import { CardInfo } from "../components/cards/Info";
import CardProject from "../components/cards/Project";
import { CarouselClient, Client } from "../components/display/carousel";
import { Section } from "../components/display/section";
import { Master } from "../components/layout/Master";

export function Category2(props) {
  return (
    <Master
      title="Verificaciones domiciliarias y Laborales"
      description="Confirma el domicilio de tus colaboradores o postulantes en menos de 24 Horas, con un reporte detallado de la vivienda o centro de labores."
      image={require("../assets/image/read/2.jpg")}
    >
      <Section light>
        Las verificaciones pueden ser domiciliarias y de referencia laboral.
        <br />
        <br />
        Contamos con más de 130 motorizados en lima metropolitana, Callao y
        algunas provincias del Perú. A través de nuestra web service, el cliente
        hace su requerimiento y esta se realiza dentro de un periodo corto de
        tiempo. Nuestra verificación domiciliaria consiste en validar la
        vivencia en el domicilio mediante el llenado de una breve encuesta la
        cual es totalmente personalizable a solicitud del cliente, Esto sumado a
        la evidencia fotográfica y la geolocalización es enviado a la central de
        Logixtal donde nuestro personal valida la información antes de ser
        enviada al cliente. Con ello obtendrás un reporte válido y acreditado
        para la certificación BASC.
      </Section>
      <Section title="Características">
        <div className="list-services">
          <CardInfo description="En la encuesta el cliente podrá agregar, editar o eliminar las preguntas en relación a su requerimiento o necesidad." />
          <CardInfo description="Todos los resultados cuentan con evidencia fotográfica conformada por Fotografía de la numeración, Fotografía de la fachada y por último Fotografía de la zona." />
          <CardInfo description="Obligatoriamente se fija la ubicación al momento de empezar la validación en el domicilio, de esta forma se obtiene la geolocalización en resultado. " />
        </div>
      </Section>
      {/* <Section title="Aplicaciones y complementos" light>
        <div className="list-services">
          {data.map(
            (card, index) =>
              card.type === "service" && (
                <CardProject
                  key={index}
                  image={card.image}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  route={card.route}
                />
              )
          )}
        </div>
      </Section> */}
    </Master>
  );
}
const data = [
  {
    image: require("../assets/image/madit/5-BiometriaFacialMadit.jpg"),
    icon: "io-madit-fav",
    title: "MADIT",
    description: "Sistema de validación biométrica y dactilar",
    route: "/madit",
    type: "service",
  },
  {
    image: require("../assets/image/home/home-veridomi.jpg"),
    icon: "io-veridomi-fav",
    title: "VERIDOMI",
    description: "Verificaciones Domiciliarias y Laborales",
    route: "/veridomi",
    type: "service",
  }
];
