import React from "react";
import { CarouselClient, Client } from "../components/display/carousel";
import { Section } from "../components/display/section";
import { CardService } from "../components/cards/Service";
import { Master } from "../components/layout/Master";

export class Logitrack extends React.Component {
  render() {
    return (
      <Master
        tag="logitrack"
        title="Plataforma Integrada para la gestión de Delivery para Tiendas & e-commerce"
        description="Logitrack es nuestro servicio de delivery inteligente basado en nuestra plataforma modular e integrable con los sistemas de tu empresa. Ideal para comercios & e-commerce así como entregas de alto valor."
        activeButtons
        demo
        image={require("./../assets/image/home/home-logitrack.jpg")}
        logo={<Logo />}
      >
        <Section light>
          LogiTrack es una herramienta modular e integrable que se encuentra
          100% en la nube. Su facilidad de uso y flexibilidad se permiten
          acoplar a cualquier negocio o e-commerce que requiera automatizar y
          agilizar el servicio de Delivery de última milla.
          <br />
          <br />
          Programar entregas, seguimiento en vivo, tipificación de entregas,
          logística inversa, integración con sistemas propios, plataformas
          e-commerce, entregas certificadas con enlace con Reniec – lo que
          necesites lo podemos hacer.
        </Section>
        <Section title="¿Qué servicios brinda Logitrack?">
          <div className="list-services">
            <CardService
              image={require("./../assets/image/logitrack/2-e-commerce.jpg")}
              title="INTEGRACIÓN CON E-COMMERCE"
              description="Contamos con un equipo in-house de desarrolladores y programadores que pueden integrarnos a las necesidades exactas del e-commerce. Desde ventanas horarias a discreción del cliente, tipificación de entregas, clientes VIP hasta integración con OMS & WMS para evitar órdenes de entrega “fantasmas” por falta de inventario o automatizar direcciones de recojo según cruce de inventario. Tú lo necesitas, nosotros lo hacemos"
            />
            <CardService
              image={require("./../assets/image/logitrack/3-HubDistribucion.jpg")}
              title="HUB DE DISTRIBUCIÓN"
              description="Contamos con un almacén amplio con los más altos estándares de calidad, preparado para paquetería pequeña, mediana y grande. Dentro de nuestras soluciones contamos con almacenamiento y resguardo de inventario de mayor rotación o mayor valor, cross-docking o cualquier solución que requiera un traslado o entrega por Delivery personalizado y en el menor tiempo posible."
            />
            <CardService
              image={require("./../assets/image/logitrack/4-Reempaquetado.jpg")}
              title="RE-EMPAQUETADO"
              description="  ¿Representas muchas marcas pero quieres reforzar la imagen de marca que como tienda tienes? Basta de empaques
            distintos para la entrega. Podemos recoger los productos, hacer el proceso de sorteado para generar las
            entregas y consolidar paquetes para que tus clientes reciban el menor número de cajas sueltas: todas con tu
            logo y el mensaje que quieras transmitir."
            />
            <CardService
              image={require("./../assets/image/logitrack/6-ContactoconCliente.jpg")}
              title="CONTACTO CON CLIENTE"
              description="Desde que se genera el pedido por el e-commerce, el cliente es parte vital y esencial del proceso de compra
            futura. Somos conscientes de ello y por eso lo mantenemos informado en los hitos más importantes posterior a
            la compra: confirmamos el día en el que se realizará la compra y, una vez activada la entrega de su producto,
            podrá monitorear en tiempo real el estado de su pedido por SMS o whatsapp."
            />
            <CardService
              image={require("./../assets/image/logitrack/7-EntregasVerificadasconReniec.jpg")}
              title="ENTREGAS VERIFICADAS"
              description="Podemos realizar entregas certificadas que requieran verificación y validación de la persona que recibe el producto."
            />
            <CardService
              image={require("./../assets/image/logitrack/8-ValidaciondeEntrega.jpg")}
              title="VALIDACIÓN DE ENTREGA"
              description="Todos nuestros repartidores son capacitados de forma constante. Además, cuentan con soporte directo y un aplicativo propio en el que deberán cargar evidencia de las entregas, lo que va desde fotos del domicilio declarado como punto de entrega, fotografía del DNI de la persona que recibe el producto, fotografía del usuario o cliente que recibe, entre otros puntos de control. Todo para brindar la mayor seguridad posible a tu negocio y tus clientes."
            />
          </div>
        </Section>
        <CarouselClient>
          <Client image={require("./../assets/image/clients/Entel.png")} />
          <Client image={require("./../assets/image/clients/BCP.png")} />
          <Client image={require("./../assets/image/clients/Scotiabank.png")} />
          <Client image={require("./../assets/image/clients/MallHogar.jpg")} />
          <Client image={require("./../assets/image/clients/Oncosalud.png")} />
        </CarouselClient>
      </Master>
    );
  }
}

const Logo = (props) => (
  <svg
    className="logo"
    width="215"
    height="60"
    viewBox="0 0 215 60"
    fill="none"
  >
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M36.3007 52.653L36.0242 52.1791L50.7576 43.2916V16.1948L26.3468 30.3357L0.553467 14.5754L26.3468 0.789978L41.0801 9.28241L40.8036 9.7564L26.3468 1.46147L1.69896 14.6149L26.3468 29.6642L51.3105 15.2074V43.6076L36.3007 52.653Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M26.3463 59.0916L0.552979 43.6077V26.0698H1.10597V43.2917L25.7933 58.1041V30.2963H26.3463V59.0916Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4522 1.10599C27.4522 1.71681 26.957 2.21198 26.3462 2.21198C25.7354 2.21198 25.2402 1.71681 25.2402 1.10599C25.2402 0.495169 25.7354 0 26.3462 0C26.957 0 27.4522 0.495169 27.4522 1.10599ZM40.9217 10.6254C41.5325 10.6254 42.0276 10.1303 42.0276 9.51944C42.0276 8.90862 41.5325 8.41345 40.9217 8.41345C40.3108 8.41345 39.8157 8.90862 39.8157 9.51944C39.8157 10.1303 40.3108 10.6254 40.9217 10.6254ZM52.1395 15.6814C52.1395 16.2922 51.6443 16.7874 51.0335 16.7874C50.4227 16.7874 49.9275 16.2922 49.9275 15.6814C49.9275 15.0705 50.4227 14.5754 51.0335 14.5754C51.6443 14.5754 52.1395 15.0705 52.1395 15.6814ZM37.5643 52.6925C37.5643 53.3034 37.0691 53.7985 36.4583 53.7985C35.8475 53.7985 35.3523 53.3034 35.3523 52.6925C35.3523 52.0817 35.8475 51.5865 36.4583 51.5865C37.0691 51.5865 37.5643 52.0817 37.5643 52.6925ZM1.10599 15.6813C1.71681 15.6813 2.21198 15.1862 2.21198 14.5754C2.21198 13.9645 1.71681 13.4694 1.10599 13.4694C0.495163 13.4694 0 13.9645 0 14.5754C0 15.1862 0.495163 15.6813 1.10599 15.6813ZM2.21198 26.3463C2.21198 26.9571 1.71681 27.4523 1.10599 27.4523C0.495163 27.4523 0 26.9571 0 26.3463C0 25.7355 0.495163 25.2403 1.10599 25.2403C1.71681 25.2403 2.21198 25.7355 2.21198 26.3463ZM26.3462 31.4023C26.957 31.4023 27.4522 30.9071 27.4522 30.2963C27.4522 29.6855 26.957 29.1903 26.3462 29.1903C25.7354 29.1903 25.2402 29.6855 25.2402 30.2963C25.2402 30.9071 25.7354 31.4023 26.3462 31.4023ZM27.4522 58.894C27.4522 59.5048 26.957 60 26.3462 60C25.7354 60 25.2402 59.5048 25.2402 58.894C25.2402 58.2832 25.7354 57.788 26.3462 57.788C26.957 57.788 27.4522 58.2832 27.4522 58.894Z"
      fill="white"
    />
    <path
      d="M33.1007 16.8268L38.7096 13.4693L26.3462 6.7149L5.60889 18.4858V21.8828L23.5417 32.5082V46.5306L11.2178 39.2626V31.4022L5.60889 28.0447V42.6201L25.7932 54.391L29.1507 52.6925V32.5082L41.4746 24.6873V39.2626L34.7596 43.1731V49.335L46.5305 42.6201V18.4858L44.2791 16.8268L26.3462 27.4917L14.0223 20.1843L26.3462 12.9164L33.1007 16.8268Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M33.1006 16.2739L35.9051 14.5754L26.3462 9.51941V12.3239L33.1006 16.2739Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.8145 40.0527H65.8856V16.7874H63.2786V42.3831H77.8145V40.0527ZM79.6707 33.8907C79.6707 38.9862 83.4231 42.7782 88.6766 42.7782C93.93 42.7782 97.722 38.9862 97.722 33.8907C97.722 28.7953 93.9695 25.0033 88.6766 25.0033C83.4231 25.0033 79.6707 28.7953 79.6707 33.8907ZM82.2776 33.8907C82.2776 30.0988 84.7661 27.0573 88.6766 27.0573C92.6266 27.0573 95.115 30.0988 95.115 33.8907C95.115 37.6827 92.6266 40.6847 88.6766 40.6847C84.7661 40.6847 82.2776 37.6827 82.2776 33.8907ZM103.884 45.7406C105.227 47.4786 107.794 48.8611 110.362 48.8611C115.22 48.8611 116.958 45.8591 116.958 42.2252V39.0257H116.879C115.576 41.1192 112.929 42.3437 110.48 42.3437C105.187 42.3437 101.751 38.7097 101.751 33.7327C101.751 28.9138 105.227 25.0033 110.48 25.0033C112.929 25.0033 115.536 26.1883 116.879 28.3608H116.958V25.4378H119.407V42.2252C119.407 45.8986 117.709 51.0336 110.243 51.0336C107.123 51.0336 104.081 49.7301 102.106 47.6366L103.884 45.7406ZM110.757 40.2502C114.549 40.2502 117.274 37.5642 117.274 33.6537C117.274 30.0988 114.549 27.0573 110.757 27.0573C106.688 27.0573 104.358 30.1383 104.358 33.6537C104.358 37.2482 106.688 40.2502 110.757 40.2502ZM125.333 18.9204C125.333 19.9473 126.202 20.6583 127.071 20.6583C127.94 20.6583 128.809 19.9473 128.809 18.9204C128.809 17.8934 127.94 17.1824 127.071 17.1824C126.202 17.1824 125.333 17.8934 125.333 18.9204ZM125.886 42.3437H128.335V25.4378H125.886V42.3437Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M202.278 33.0612H202.159V15.0494H199.71V42.3437H202.159V33.4562H202.278L210.889 42.3437H214.483L205.082 33.1797L213.654 25.4378H210.099L202.278 33.0612ZM145.872 27.4918H141.093V37.3667C141.093 39.8947 142.159 40.4872 143.621 40.4872C144.292 40.4872 145.122 40.2897 145.754 39.9342L145.872 42.0671C145.003 42.3831 144.095 42.5806 142.989 42.5806C141.172 42.5806 138.644 41.7906 138.644 38.0777V27.4918H135.128V25.4378H138.644V20.6583H141.093V25.4378H145.872V27.4918ZM149.546 25.4378H151.876C151.955 26.3463 152.034 27.6103 152.034 28.3213H152.113C153.061 26.3858 155.155 25.0033 157.525 25.0033C158.157 25.0033 158.631 25.0428 159.144 25.2008L158.828 27.5313C158.552 27.4128 157.762 27.3338 157.09 27.3338C154.483 27.3338 152.153 29.2298 152.153 33.7327V42.4227H149.704V29.1508C149.692 28.8808 149.683 28.5345 149.674 28.1499V28.148V28.1474C149.653 27.2946 149.628 26.2538 149.546 25.4378ZM168.426 27.0573C171.349 27.0573 172.929 28.5188 172.929 31.4417V31.9947C166.728 31.9947 161 32.4687 161 37.6827C161 41.4352 164.279 42.8176 166.846 42.8176C169.572 42.8176 171.468 41.8697 173.008 39.6182H173.087C173.087 40.5267 173.166 41.5537 173.324 42.3832H175.536C175.378 41.4352 175.26 39.8947 175.26 38.5912V31.1652C175.26 27.0178 172.297 25.0033 168.663 25.0033C165.78 25.0033 163.41 26.0303 162.027 27.4128L163.41 29.0323C164.595 27.8473 166.451 27.0573 168.426 27.0573ZM163.489 37.5247C163.489 34.3252 168.15 33.8907 172.179 33.8907V33.8512H172.89V35.1152C172.89 38.1172 170.954 40.7242 167.478 40.7242C165.543 40.7242 163.489 39.9342 163.489 37.5247ZM193.746 29.3088C192.956 28.0053 191.258 27.0573 189.401 27.0573C185.451 27.0573 182.923 30.0198 182.923 33.8907C182.923 37.7222 185.412 40.6847 189.362 40.6847C191.495 40.6847 192.956 39.7762 193.944 38.4727L195.8 39.8552C194.339 41.6327 192.285 42.7782 189.362 42.7782C183.911 42.7782 180.316 38.9862 180.316 33.8907C180.316 28.7953 183.911 25.0033 189.362 25.0033C191.653 25.0033 194.22 25.9908 195.642 27.9263L193.746 29.3088Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="31.1257"
        y1="9.51941"
        x2="31.1257"
        y2="16.2739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#127DFF" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
