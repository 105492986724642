import React from "react";
import {motion} from "framer-motion"
import {Footer} from "./Footer";
import {Navigation} from "./Navigation";
import ReactGA from "react-ga";
import {swipeScroll} from "../../io/io";
import Parallax from "../display/parallax";

export class Master extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      side: false,
    }
  }

  open_side = (state) => {
    this.setState({side: state})
    ReactGA.event({
      category: 'navigation',
      action: 'Servicios',
      label: 'desktop navigation'
    });
  }

  render() {
    return (
      <main className={this.props.tag || ''} onScroll={() => swipeScroll('main')}>
        <Navigation visible={this.state.side} onClose={() => this.open_side(false)}/>
        <Parallax {...this.props} openSide={() => this.open_side(true)}/>
        {this.props.popup && <PopUp {...this.props} onClose={this.props.closePopup}/>}
        {this.props.children}
        <Footer/>
      </main>
    )
  }
}

const PopUp = (props) => (
  <div className={'io-popup ' + props.tag}>
    <motion.div
      className="mask"
      onClick={props.onClose}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20
      }}
    />
    <motion.div
      className="content"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <div className="close" onClick={props.onClose}>
        <svg viewBox="0 0 352 512">
          <g className="fa-group">
            <path fill="currentColor"
                  d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
                  opacity=".6"/>
            <path fill="currentColor"
                  d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"/>
          </g>
        </svg>
      </div>
      <div className="all">
        <div className="info">
          <div className="left">
            <div>
              <img className='image-document' src={require('../../assets/image/profile/document-profile.jpg')} alt=""/>
            </div>
            <div>
              <img className='image-profile' src={require('../../assets/image/profile/profile_comparation_rect.jpg')} alt=""/>
            </div>
          </div>
          <div className="right">
            <h2>¡MADIT a la Vanguardia!</h2>
            <p>Seguimos avanzando para brindarte más y mejores soluciones.</p>
            <p className='new'>Conoce la <span className='io-new'>Nueva</span> <br/><strong>BIOMETRÍA FACIAL</strong></p>
          </div>
        </div>
        <p className='title'>Contacta con tu Ejecutivo para Mayor Información</p>
        <div className="contact">
          {/* <Profile image={require('../../assets/image/profile/Jair_round.jpg')} name='Jair Alva' mail='jair.alva.p@logixtal.com'/> */}
          <Profile image={require('../../assets/image/profile/Johel_round.jpg')} name='Johel Lara' mail='johel.lara@logixtal.com'/>
          <Profile image={require('../../assets/image/profile/Giorgio_round.jpg')} name='Giorgio Garibotto' mail='giorgio.garibotto@logixtal.com'/>
        </div>
      </div>
    </motion.div>
  </div>
)

const Profile = (props) => (
  <div className='io-profile'>
    <div className='image'>
      <img
        src={props.image || "https://images.pexels.com/photos/1547971/pexels-photo-1547971.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
        alt=""/>
    </div>
    <div className='user'>
      <p>{props.name}</p>
      <a href={'mailto:' + props.mail}>{props.mail}</a>
    </div>
  </div>
)

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};