import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {Home} from "./screens/home";
import {Madit} from "./screens/madit";
import {Logitrack} from "./screens/logitrack";
import {Veridomi} from "./screens/veridomi";
import {BPO} from "./screens/bpo";
import {Medixtal} from "./screens/medixtal";
import {Politics} from "./screens/politics";
import {Works} from "./screens/works";
import { Category1 } from "./screens/category1";
import { Category2 } from "./screens/category2";
import { Category3 } from "./screens/category3";
import { Category4 } from "./screens/category4";
import { Category5 } from "./screens/category5";
import { Category6 } from "./screens/category6";

class AppInside extends React.Component {
  render() {
    return(
      <Router>
        <Route key={1} exact={true} path="/" component={(props) => <Home {...props} />}/>
        <Route key={10} exact={true} path="/madit" component={(props) => <Madit {...props} />}/>
        <Route key={11} exact={true} path="/logitrack" component={(props) => <Logitrack {...props} />}/>
        <Route key={12} exact={true} path="/veridomi" component={(props) => <Veridomi {...props} />}/>
        <Route key={13} exact={true} path="/bpo" component={(props) => <BPO {...props} />}/>
        <Route key={14} exact={true} path="/medixtal" component={(props) => <Medixtal {...props} />}/>
        <Route key={15} exact={true} path="/distribucion-valorados" component={(props) => <Category1 {...props} />}/>
        <Route key={16} exact={true} path="/verificacion-domiciliaria-laboral" component={(props) => <Category2 {...props} />}/>
        {/* <Route key={17} exact={true} path="/recojo-envio-documentacion" component={(props) => <Category3 {...props} />}/> */}
        <Route key={17} exact={true} path="/validadcion-biometrica" component={(props) => <Category5 {...props} />}/>
        <Route key={18} exact={true} path="/logistica-transporte-ecommerce" component={(props) => <Category4 {...props} />}/>
        <Route key={19} exact={true} path="/desarrollo-softwares" component={(props) => <Category6 {...props} />}/>

        <Route key={20} exact={true} path="/politicas-de-privacidad" component={(props) => <Politics {...props} />}/>
        <Route key={21} exact={true} path="/trabaja-con-nosotros" component={(props) => <Works {...props} />}/>
      
      </Router>
    )
  }
}

export default AppInside