import React from "react";
import {CarouselClient, Client} from "../components/display/carousel";
import {Section} from "../components/display/section";
import {CardService} from "../components/cards/Service";
import {Master} from "../components/layout/Master";
import {CardProduct} from "../components/cards/Product";

export class Medixtal extends React.Component {
  render() {
    return (
      <Master
        tag='medixtal'
        title='Línea médica de Logixtal'
        description='La línea médica de Logixtal cuenta con un catálogo creciente de productos e insumos  de la más alta calidad y con certificaciones internacionales en los que nuestros clientes confían.'
        image={require('./../assets/image/medixtal/medixtal.jpg')}
        logo={(<Logo/>)}
        activeButtons
        see
      >
        <Section light>
          Medixtal es nuestra marca para la importación y comercialización de insumos y equipo médico y sanitario. A lo largo de nuestra trayectoria, hemos apostado por la calidad de los productos que comercializamos, las amplias opciones de marcas y productos, tecnología de vanguardia, atención personalizada, asesoría técnica, stock continuo y eficiencia en la entrega a nuestros clientes – donde estos se encuentren.
          <br/>
          <br/>
          Dentro de nuestro portafolio en constante crecimiento encontrarán mascarillas bajo distinto estándares, indumentaria y equipo médico, equipos concentradores de oxígeno, entre otros. Nuestro norte es seguir creciendo, enfocados siempre en productos con una excelente relación precio / beneficio.
        </Section>
        <div id='product'/>
        <Section title='Productos de Medixtal'>
          <div className="list-services list-product">
            <CardProduct
              image={require('./../assets/image/medixtal/product/kn95.jpg')}
              description='Filtra hasta el 95% de las partículas'
              title='MASCARILLA KN-95'
              detail={(
                <>
                  <p>Tipo KN-95 según Estándar GB 2026:2006</p>
                  <p>Certificación FDA, CE & GB</p>
                  <p>Bolsa Sellada Ziploc de 10 Unidades</p>
                  <p>Dobladas</p>
                  <p>Talla estándar adultos con clip nasal y orejeras</p>
                </>
              )}
            />
            <CardProduct
              image={require('./../assets/image/medixtal/product/n95.jpg')}
              description='Para pacientes con enfermedades respiratorias altamente infecciosas'
              title='MASCARILLA N-95'
              detail={(
                <>
                  <p>Tipo N-95 según Estándar NIOSH</p>
                  <p>Certificado NIOSH N° TC-84A-7863</p>
                  <p>Caja Sellada de 20  unidades</p>
                  <p>Preformadas</p>
                  <p>Talla estándar adultos con clip nasal y orejeras</p>
                </>
              )}
            />
            <CardProduct
              image={require('./../assets/image/medixtal/product/mandil.jpg')}
              description='Biodegradable, resistente al desgarre.'
              title='MANDIL SMS NO ESTÉRIL'
              detail={(
                <>
                  <p>Mandil desechable de 40 gramos/m2</p>
                  <p>Tela no tejida de Polipropileno SMS</p>
                  <p>Puños de algodón con doble elástico resistente (RIBB)</p>
                  <p>Color azul</p>
                  <p>Respirable, no permite el paso de fluidos</p>
                  <p>Aséptico y Atóxico</p>
                </>
              )}
            />

          </div>
        </Section>

      </Master>
    )
  }
}

// const Logo = (props) => (
//   <div className='logo io-row center'>
//     <svg width="40" height="31" viewBox="0 0 60 31">
//       <path
//         d="M34.559 14.4685L32.4538 20.0128L24.8979 0L18.4395 14.4685H0V18.3591H20.9634L24.5973 10.2184L32.4434 31L37.2435 18.3591H59.6562V14.4685H34.559Z"
//         fill="white"/>
//     </svg>
//     <div className="io-space vertical small"/>
//     <p>MEDIXTAL</p>
//   </div>
// )

const Logo =(props) => (
  <svg className='logo' width="191" height="45" viewBox="0 0 191 45" fill="none">
    <path opacity="0.1" d="M23.7512 44.4286C36.0198 44.4286 45.9655 34.4829 45.9655 22.2143C45.9655 9.94569 36.0198 0 23.7512 0C11.4826 0 1.53687 9.94569 1.53687 22.2143C1.53687 34.4829 11.4826 44.4286 23.7512 44.4286Z" fill="white"/>
    <path opacity="0.25" d="M45.9655 22.3605C45.9655 34.6293 36.0199 44.5748 23.7512 44.5748C11.4824 44.5748 1.53741 34.6293 1.53741 22.3605C1.53741 10.0918 12.4711 17.3469 24.7393 17.3469C37.0074 17.3469 45.9655 10.0918 45.9655 22.3605Z" fill="white"/>
    <path d="M2.63492 19.3963H11.1253C11.1253 19.3963 15.2239 25.1055 14.8583 25.0323C14.4921 24.9592 14.4921 24.9592 14.4921 24.9592L21.0794 0.732422L25.8368 15.2245L29.4416 7.55443L33.7421 25.3249L37.9139 19.25H47.5023V23.2024H40.5494L32.2053 35.2058L27.521 21.8849L22.9099 32.1316L20.4944 20.1281L16.3226 35.8646L10.0278 22.9093H0V19.3226H3.22053" fill="#FEFEFE"/>
    <path d="M62.6946 11L70.1004 23.7992L77.478 11H80.1754V33.8304H77.2224V17.3353L70.1018 29.6643L62.953 17.3353V33.8304H60V11H62.6946Z" fill="white"/>
    <path d="M97.4528 11V13.953H88.1066V20.9309H97.4528V23.8854H88.1066V30.8633H97.4528V33.8318H85.1536V11H97.4528Z" fill="white"/>
    <path d="M103.547 11C107.257 11.0198 110.149 12.0705 112.222 14.1535C114.496 16.4456 115.633 19.1995 115.633 22.4152C115.633 25.6394 114.496 28.3932 112.222 30.6768C110.149 32.7599 107.257 33.8106 103.547 33.8304H101.377V11H103.547ZM104.33 14.0109V30.8336C106.68 30.8336 108.607 30.0781 110.109 28.5655C111.803 26.8723 112.649 24.8231 112.649 22.4152C112.649 20.0186 111.801 17.968 110.109 16.2649C108.607 14.7622 106.68 14.0109 104.33 14.0109Z" fill="white"/>
    <path d="M122.452 11V33.8304H119.499V11H122.452Z" fill="white"/>
    <path d="M125.606 33.8306L132.783 21.3745L126.805 11.0016H130.201L134.482 18.4357L138.791 11.0016H142.187L136.179 21.3745L143.385 33.8306H139.988L134.481 24.3134L129.001 33.8306H125.606Z" fill="white"/>
    <path d="M153.115 13.953V33.829H150.147V13.953H144.824V11H158.436V13.953H153.115Z" fill="white"/>
    <path d="M168.726 11L177.03 33.8304H173.891L170.952 25.7538H164.902L161.977 33.8304H158.823L167.127 11H168.726ZM169.881 22.8007L167.927 17.4497L165.986 22.8007H169.881Z" fill="white"/>
    <path d="M179.884 33.8304V11H182.839V30.8619H191V33.8304H179.884Z" fill="white"/>
  </svg>
)

