import React from "react";

export const IconDrag = (props) => (
  <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H8.5C8.77614 0 9 0.223858 9 0.5C9 0.776142 8.77614 1 8.5 1H0.5C0.223858 1 0 0.776142 0 0.5ZM0 3.5C0 3.22386 0.223858 3 0.5 3H8.5C8.77614 3 9 3.22386 9 3.5C9 3.77614 8.77614 4 8.5 4H0.5C0.223858 4 0 3.77614 0 3.5ZM0.5 6C0.223858 6 0 6.22386 0 6.5C0 6.77614 0.223858 7 0.5 7H8.5C8.77614 7 9 6.77614 9 6.5C9 6.22386 8.77614 6 8.5 6H0.5Z"
      fill="#BDBDBD"
    />
  </svg>
);
export const IconBpo = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="10" fill="url(#paint0_linear)" />
    <path
      d="M21.982 18.488C23.958 18.64 25.5413 19.3113 26.732 20.502C27.948 21.6673 28.556 23.124 28.556 24.872C28.556 26.2147 28.2267 27.4307 27.568 28.52C26.9347 29.6093 26.048 30.4707 24.908 31.104C23.768 31.712 22.4887 32.016 21.07 32.016C20.2593 32.016 19.3727 31.978 18.41 31.902C18.0553 31.8767 17.6373 31.8513 17.156 31.826C16.6747 31.8007 16.13 31.788 15.522 31.788L12.976 31.826C12.52 31.8513 11.8867 31.864 11.076 31.864C11.0253 31.864 11 31.788 11 31.636C11 31.484 11.0253 31.408 11.076 31.408C12.0133 31.408 12.6973 31.3447 13.128 31.218C13.584 31.0913 13.888 30.8507 14.04 30.496C14.2173 30.116 14.306 29.546 14.306 28.786V11.192C14.306 10.432 14.23 9.87467 14.078 9.52C13.926 9.16533 13.6347 8.92467 13.204 8.798C12.7733 8.646 12.0767 8.57 11.114 8.57C11.0633 8.57 11.038 8.494 11.038 8.342C11.038 8.19 11.0633 8.114 11.114 8.114L12.976 8.152C14.04 8.20267 14.8887 8.228 15.522 8.228C15.978 8.228 16.396 8.21533 16.776 8.19C17.1813 8.16467 17.5233 8.13933 17.802 8.114C18.5873 8.038 19.322 8 20.006 8C22.0833 8 23.6413 8.46867 24.68 9.406C25.7187 10.318 26.238 11.5213 26.238 13.016C26.238 14.232 25.858 15.3213 25.098 16.284C24.338 17.2213 23.2993 17.956 21.982 18.488ZM19.17 8.532C18.5113 8.532 18.0173 8.59533 17.688 8.722C17.384 8.82333 17.156 9.064 17.004 9.444C16.852 9.824 16.776 10.432 16.776 11.268V18.488L18.486 18.526C20.2847 18.526 21.602 18.0573 22.438 17.12C23.2993 16.1573 23.73 14.8907 23.73 13.32C23.73 11.876 23.3627 10.7233 22.628 9.862C21.8933 8.97533 20.7407 8.532 19.17 8.532ZM20.5 31.37C22.324 31.37 23.6793 30.8887 24.566 29.926C25.4527 28.9633 25.896 27.5953 25.896 25.822C25.896 23.8713 25.3387 22.3133 24.224 21.148C23.1347 19.9573 21.602 19.3493 19.626 19.324C18.8153 19.2987 17.8653 19.324 16.776 19.4V28.786C16.776 29.4447 16.8647 29.964 17.042 30.344C17.2447 30.6987 17.612 30.9647 18.144 31.142C18.676 31.294 19.4613 31.37 20.5 31.37Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="34.5"
        y1="2.5"
        x2="2.5"
        y2="37.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0073FF" />
        <stop offset="1" stopColor="#00DBFB" />
      </linearGradient>
    </defs>
  </svg>
);
export const IconMadit = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="10" fill="url(#paint0_linear)" />
    <path
      d="M30.3347 11H27.8326L23.046 21.7031L18.205 11H17.9331V16.7338L22.5021 27H23.5356L28.3766 16.1331L30.2259 27H33L30.3347 11ZM13.0377 27H15.8117L17.1715 18.9181H14.3975L13.0377 27ZM7 27H9.77406L11.1339 18.9181H8.35983L7 27Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="34.5"
        y1="2.5"
        x2="2.5"
        y2="37.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0073FF" />
        <stop offset="1" stopColor="#00DBFB" />
      </linearGradient>
    </defs>
  </svg>
);
export const IconLogitrack = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="10" fill="url(#paint0_linear)" />
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M25.71 30.2363L25.5828 30.0181L32.3646 25.9272V13.4544L21.1282 19.9636L9.25546 12.709L21.1282 6.36353L27.91 10.2726L27.7828 10.4908L21.1282 6.67262L9.78274 12.7272L21.1282 19.6545L32.6191 12.9999V26.0727L25.71 30.2363Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M21.1273 33.2L9.25458 26.0727V18H9.50912V25.9273L20.8728 32.7455V19.9455H21.1273V33.2Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6364 6.50909C21.6364 6.79026 21.4084 7.01818 21.1273 7.01818C20.8461 7.01818 20.6182 6.79026 20.6182 6.50909C20.6182 6.22793 20.8461 6 21.1273 6C21.4084 6 21.6364 6.22793 21.6364 6.50909ZM27.8364 10.8909C28.1175 10.8909 28.3454 10.663 28.3454 10.3818C28.3454 10.1007 28.1175 9.87275 27.8364 9.87275C27.5552 9.87275 27.3273 10.1007 27.3273 10.3818C27.3273 10.663 27.5552 10.8909 27.8364 10.8909ZM33 13.2182C33 13.4993 32.7721 13.7273 32.4909 13.7273C32.2097 13.7273 31.9818 13.4993 31.9818 13.2182C31.9818 12.937 32.2097 12.7091 32.4909 12.7091C32.7721 12.7091 33 12.937 33 13.2182ZM26.291 30.2546C26.291 30.5357 26.063 30.7637 25.7819 30.7637C25.5007 30.7637 25.2728 30.5357 25.2728 30.2546C25.2728 29.9734 25.5007 29.7455 25.7819 29.7455C26.063 29.7455 26.291 29.9734 26.291 30.2546ZM9.50909 13.2182C9.79026 13.2182 10.0182 12.9903 10.0182 12.7091C10.0182 12.4279 9.79026 12.2 9.50909 12.2C9.22793 12.2 9 12.4279 9 12.7091C9 12.9903 9.22793 13.2182 9.50909 13.2182ZM10.0182 18.1273C10.0182 18.4085 9.79026 18.6364 9.50909 18.6364C9.22793 18.6364 9 18.4085 9 18.1273C9 17.8462 9.22793 17.6182 9.50909 17.6182C9.79026 17.6182 10.0182 17.8462 10.0182 18.1273ZM21.1273 20.4546C21.4084 20.4546 21.6364 20.2267 21.6364 19.9455C21.6364 19.6644 21.4084 19.4364 21.1273 19.4364C20.8461 19.4364 20.6182 19.6644 20.6182 19.9455C20.6182 20.2267 20.8461 20.4546 21.1273 20.4546ZM21.6364 33.1092C21.6364 33.3903 21.4084 33.6183 21.1273 33.6183C20.8461 33.6183 20.6182 33.3903 20.6182 33.1092C20.6182 32.828 20.8461 32.6001 21.1273 32.6001C21.4084 32.6001 21.6364 32.828 21.6364 33.1092Z"
      fill="white"
    />
    <path
      d="M24.2364 13.7455L26.8182 12.2L21.1273 9.09094L11.5818 14.5091V16.0728L19.8364 20.9637V27.4183L14.1636 24.0728V20.4546L11.5818 18.9091V25.6182L20.8727 31.0364L22.4182 30.2546V20.9637L28.0909 17.3637V24.0728L25 25.8728V28.7092L30.4182 25.6182V14.5091L29.3819 13.7455L21.1273 18.6546L15.4546 15.291L21.1273 11.9455L24.2364 13.7455Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M24.2364 13.4909L25.5273 12.7091L21.1273 10.3818V11.6727L24.2364 13.4909Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="34.5"
        y1="2.5"
        x2="2.5"
        y2="37.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0073FF" />
        <stop offset="1" stopColor="#00DBFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="23.3273"
        y1="10.3818"
        x2="23.3273"
        y2="13.4909"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#127DFF" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export const IconVeridomi = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="10" fill="url(#paint0_linear)" />
    <path
      d="M28.1707 28.9898H12.4909C11.6683 28.9898 11 28.3215 11 27.4989V11.8191C11 10.9966 11.6683 10.3282 12.4909 10.3282H28.1707C28.9932 10.3282 29.6616 10.9966 29.6616 11.8191V27.5118C29.6487 28.3215 28.9804 28.9898 28.1707 28.9898ZM12.478 11.5492C12.3366 11.5492 12.2081 11.6649 12.2081 11.8191V27.5118C12.2081 27.6532 12.3238 27.7817 12.478 27.7817H28.1578C28.2992 27.7817 28.4277 27.666 28.4277 27.5118V11.8191C28.4277 11.6777 28.3121 11.5492 28.1578 11.5492H12.478V11.5492Z"
      fill="white"
      fillOpacity="0.7"
    />
    <path
      d="M13.5833 13.1172L20.5364 25.7125L37.7457 2L20.3822 19.7233L13.5833 13.1172Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="34.5"
        y1="2.5"
        x2="2.5"
        y2="37.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0073FF" />
        <stop offset="1" stopColor="#00DBFB" />
      </linearGradient>
    </defs>
  </svg>
);
export const IconMedixtal = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="10" fill="url(#paint0_linear)" />
    <path
      opacity="0.2"
      d="M20.4523 34.9049C28.4342 34.9049 34.9048 28.4343 34.9048 20.4524C34.9048 12.4706 28.4342 6 20.4523 6C12.4705 6 5.99988 12.4706 5.99988 20.4524C5.99988 28.4343 12.4705 34.9049 20.4523 34.9049Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M34.9048 20.5476C34.9048 28.5295 28.4342 35 20.4523 35C12.4704 35 6.00024 28.5295 6.00024 20.5476C6.00024 12.5656 13.1136 17.2857 21.0952 17.2857C29.0767 17.2857 34.9048 12.5656 34.9048 20.5476Z"
      fill="white"
    />
    <path
      d="M6.71426 18.6191H12.238C12.238 18.6191 14.9046 22.3335 14.6667 22.2859C14.4284 22.2383 14.4284 22.2383 14.4284 22.2383L18.7141 6.47656L21.8092 15.905L24.1545 10.9149L26.9523 22.4762L29.6664 18.524H35.9046V21.0953H31.3811L25.9525 28.9047L22.9049 20.2382L19.905 26.9046L18.3334 19.0952L15.6193 29.3332L11.524 20.9047H5V18.5712H7.09525"
      fill="#FEFEFE"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="34.5"
        y1="2.5"
        x2="2.5"
        y2="37.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4297FF" />
        <stop offset="1" stopColor="#0073FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const IconFeature1 = (props) => (
  <svg
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="9" />
    <polyline points="12 7 12 12 15 15" />
  </svg>
);

export const IconFeature2 = (props) => (
  <svg
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="9" />
    <path d="M9 12l2 2l4 -4" />
  </svg>
);

export const IconFeature3 = (props) => (
  <svg
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="3" y="4" width="18" height="12" rx="1" />
    <path d="M7 20h10" />
    <path d="M9 16v4" />
    <path d="M15 16v4" />
    <path d="M9 12v-4" />
    <path d="M12 12v-1" />
    <path d="M15 12v-2" />
    <path d="M12 12v-1" />
  </svg>
);

export const IconFeature4 = (props) => (
  <svg
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="4" y="4" width="6" height="6" rx="1" />
    <rect x="4" y="14" width="6" height="6" rx="1" />
    <rect x="14" y="14" width="6" height="6" rx="1" />
    <line x1="14" y1="7" x2="20" y2="7" />
    <line x1="17" y1="4" x2="17" y2="10" />
  </svg>
);

export const IconFeature5 = (props) => (
  <svg
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
    <circle cx="12" cy="11" r="1" />
    <line x1="12" y1="12" x2="12" y2="14.5" />
  </svg>
);
