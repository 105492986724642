import React from "react";
import { CardInfo } from "../components/cards/Info";
import CardProject from "../components/cards/Project";
import { CardService } from "../components/cards/Service";
import { CarouselClient, Client } from "../components/display/carousel";
import { Section } from "../components/display/section";
import { Master } from "../components/layout/Master";

export function Category5(props) {
  return (
    <Master
      title="Plataforma de validación biométrica"
      description="Solución de validación biométrica que permite la identificación de las  personas mediante la biometría dactilar."
      image={require("../assets/image/read/4.jpg")}
    >
      <Section light>
      Nuestra solución de reconocimiento biométrico se brinda a través de una plataforma integral que permite validar la identidad de una persona por medio de la biometría dactilar. Esta validación se logra por medio de las consultas que realiza nuestros sistemas mediante dispositivos de escritorio o móviles, brindando así la posibilidad de realizar trabajos de campo sin tener que transportar equipamiento de alto valor.
      </Section>
      <Section title="Características">
        <div className="list-services">
          <CardInfo description="Solución en APP Móvil" />
          <CardInfo description="Servicio en la nube." />
          <CardInfo description="Alta confiabilidad." />
          <CardInfo description="Cero fraudes." />
          <CardInfo description="Solución llave en mano." />
          <CardInfo description="SaaS on-demand." />
          <CardInfo description="Protocolo de seguridad Biosecure." />
        </div>
      </Section>
      <Section title='Servicios' light>
          <div className="list-services">
            <CardService
              image={require('./../assets/image/madit/2-BiometriaDactilarMadit.jpg')}
              title='BIOMETRIA DACTILAR'
              description='El resultado que nos retorna nos informa si la huella dactilar corresponde con la identidad de la persona verificada. De igual manera, Madit también comercializa lectores biométricos de marca FUTRONIC modelo FS88H con características de detección de huella viva y mayores parámetros de seguridad para minimizar el riesgo de suplantación de identidad.'
            />
            
          </div>
        </Section>

      {/* <Section title="Aplicaciones y complementos" light>
        <div className="list-services">
          {data.map(
            (card, index) =>
              card.type === "service" && (
                <CardProject
                  key={index}
                  image={card.image}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  route={card.route}
                />
              )
          )}
        </div>
      </Section> */}
    </Master>
  );
}
const data = [
  {
    image: require("../assets/image/madit/1-Madit.jpg"),
    icon: "io-bpo-fav",
    title: "BPO",
    description: "Externalización de Procesos de Negocios",
    route: "/bpo",
    type: "service",
  },
  {
    image: require("../assets/image/madit/5-BiometriaFacialMadit.jpg"),
    icon: "io-madit-fav",
    title: "MADIT",
    description: "Sistema de validación biométrica y dactilar",
    route: "/madit",
    type: "service",
  },
];
