import React from "react";
import {CarouselClient, Client} from "../components/display/carousel";
import {Section} from "../components/display/section";
import {CardService} from "../components/cards/Service";
import {Master} from "../components/layout/Master";

export class Madit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view_popup: false,
      a_1: '',
      a_2: '',
      a_3: '',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.onPopup()
    }, 3000)
    this.animate()
  }

  onPopup = () => {
    this.setState({view_popup: true})
  }

  closePopup = () => {
    this.setState({view_popup: false})
  }

  animate = () => {
    let a = ['prev', 'active', 'next']
    const t = 4000
    setTimeout(()=>{
      this.setState({
        a_1: a[0],
        a_2: a[1],
        a_3: a[2]
      })
      setTimeout(() => {
        this.setState({
          a_1: a[2],
          a_2: a[0],
          a_3: a[1]
        })
        setTimeout(() => {
          this.setState({
            a_1: a[1],
            a_2: a[2],
            a_3: a[0]
          })
          setTimeout(() => {
            this.animate()
          }, t)
        }, t)
      }, t)
    }, 0)
  }

  render() {
    return (
      <Master
        tag='madit'
        title='Sistema de validación biométrica facial y dactilar'
        description='Nuestro sistema de validación cumple con todos los protocolos para corroborar identificaciones de personas mediante la biometría dactilar y facial'
        activeButtons
        app
        demo
        image={require('./../assets/image/home/home-madit.jpg')}
        logo={(<Logo/>)}
        popup={this.state.view_popup}
        closePopup={this.closePopup}
        contentParallax={(
          <div className="section-swipe">
            <div className={'horizontal ' + this.state.a_1}>
              <img
                src={require('./../assets/image/madit/4-ConsultaIntegradaMadit.jpg')}
                alt="image"/>
              <div className="gradient">
                <p>Consulta Integrada</p>
              </div>
            </div>
            <div className={'zoom ' + this.state.a_2}>
              <img
                src={require('./../assets/image/madit/biometria-facial.jpg')}
                alt="image"/>
              <div className="gradient">
                <p>Biometría Facial</p>
              </div>
            </div>
            <div className={'default ' + this.state.a_3}>
              <img
                src={require('./../assets/image/madit/biometria-dactilar.jpg')}
                alt="image"/>
              <div className="gradient">
                <p>Biometría Dactilar</p>
              </div>
            </div>
          </div>
        )}
      >
        <Section light>
        Ponemos a disposición de nuestros clientes un software como servicio (SaaS) en la nube que utiliza dispositivos biométricos integrados con sus smartphones o periféricos para verificar la identidad de las personas.
        </Section>
        <Section title='¿Qué servicios brinda Madit?'>
          <div className="list-services">
            <CardService
              image={require('./../assets/image/madit/2-BiometriaDactilarMadit.jpg')}
              title='BIOMETRIA DACTILAR'
              description='El resultado que nos retorna nos informa si la huella dactilar corresponde con la identidad de la persona verificada. De igual manera, Madit también comercializa lectores biométricos de marca FUTRONIC modelo FS88H con características de detección de huella viva y mayores parámetros de seguridad para minimizar el riesgo de suplantación de identidad.'
            />
            {/*<CardService*/}
            {/*  image={require('./../assets/image/madit/consulta-datos.jpg')}*/}
            {/*  title='CONSULTA DE DATOS'*/}
            {/*  description='Este servicio permite la consulta de datos completos registrados de una persona con solo tener el número de DNI. La información que se recibe se puede emplear para la generación de un formato 100% personalizable, ajustado a  los sistemas de su empresa o se puede enviar por trama para poder trabajarla según su requerimiento.'*/}
            {/*/>*/}
            {/*<CardService*/}
            {/*  image={require('./../assets/image/madit/4-ConsultaIntegradaMadit.jpg')}*/}
            {/*  title='CONSULTA INTEGRADA'*/}
            {/*  description='Es la combinación del servicio de biometría dactilar y el de consulta de datos. De esta forma, podemos generar fichas completas y generar una doble verificación de la identidad del usuario. Esto ayuda mucho en situaciones como validación biométrica del usuario para firma de contratos diversos, otorgamiento de tarjetas de crédito, validación de entregas de alto valor, etc.'*/}
            {/*/>*/}
            <CardService
              image={require('./../assets/image/madit/5-BiometriaFacialMadit.jpg')}
              title='BIOMETRÍA FACIAL'
              description='Solución para PC y celular que utiliza motores de reconocimiento de rostro vivo y análisis de semejanza con tecnología de calidad para evaluar la fotografía tomada en vivo con la identidad real de la persona evaluada.'
            />
          </div>
        </Section>
        <CarouselClient>
          {/* <Client image={require('./../assets/image/madit/clients/NOTARIAPAINO.png')}/> */}
          <Client image={require('./../assets/image/madit/clients/MichaelPage.png')}/>
          {/* <Client image={require('./../assets/image/madit/clients/NotariaFernandini.jfif')}/> */}
          <Client image={require('./../assets/image/madit/clients/Adecco.png')}/>
          {/* <Client image={require('./../assets/image/madit/clients/NOTARIAPAINO.png')}/> */}
          <Client image={require('./../assets/image/madit/clients/Niubiz.png')}/>
          <Client image={require('./../assets/image/madit/clients/IBRLOGO.png')}/>
          <Client image={require('./../assets/image/madit/clients/KonectaBTO.png')}/>
          <Client image={require('./../assets/image/madit/clients/EFIDE.png')}/>
          <Client image={require('./../assets/image/madit/clients/AccesoCrediticio.jfif')}/>
          <Client image={require('./../assets/image/madit/clients/LinkConsulting.png')}/>
          <Client image={require('./../assets/image/madit/clients/Solgas.png')}/>
          <Client image={require('./../assets/image/madit/clients/CobraPeru.png')}/>
          {/* <Client image={require('./../assets/image/madit/clients/NotariaRosalesSepulveda.jpeg')}/> */}
        </CarouselClient>
      </Master>
    )
  }
}

const Logo = (props) => (
  <svg className="logo" width="177" height="30" viewBox="0 0 177 30" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M42.9 0H38.3L29.5 19.6L20.6 0H20.1V10.5L28.5 29.3H30.4L39.3 9.4L42.7 29.3H47.8L42.9 0ZM11.1 29.3H16.2L18.7 14.5H13.6L11.1 29.3ZM0 29.3H5.10001L7.60001 14.5H2.5L0 29.3ZM78.5 22H66.2L63.5 29.3H58.2L70.1 0H74.4L86.7 29.3H81.3L78.5 22ZM68.1 17.4H76.6L72.3 6.8L68.1 17.4ZM109.4 29.3C112.1 29.3 114.7 28.6 116.9 27.4C119.1 26.2 120.9 24.4 122.2 22.1C123.5 19.9 124.1 17.4 124.1 14.6C124.1 11.9 123.5 9.3 122.2 7.1C120.9 4.9 119.1 3.1 116.9 1.9C114.7 0.599998 112.2 0 109.4 0H99.5V29.3H109.4ZM109.2 4.7C111.2 4.8 112.9 5.2 114.3 6C115.8 6.9 116.9 8 117.7 9.5C118.6 11 119 12.7 119 14.6C119.1 20.2 114.8 24.5 109.2 24.5H104.5V4.7H109.2ZM138.1 0H142.4V29.3H138.1V0ZM163.7 29.3H168.6V4.8H176.9V0H155.4V4.8H163.7V29.3Z"
          fill="white"/>
  </svg>
)

