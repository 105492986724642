import React from "react";
import { CardInfo } from "../components/cards/Info";
import CardProject from "../components/cards/Project";
import { CardService } from "../components/cards/Service";
import { CarouselClient, Client } from "../components/display/carousel";
import { Section } from "../components/display/section";
import { Master } from "../components/layout/Master";

export function Category6(props) {
  return (
    <Master
      title="Desarrollo de Software (SaaS)"
      description="Logixtal, tu aliado en innovación tecnológica. ¿Qué podemos hacer por ti? Lo que NECESITES."
      image={require("../assets/image/read/7.jpg")}
    >
      <Section light>
      Estamos convencidos que el soporte tecnológico y estar a la vanguardia en sistemas son puntos claves para que las empresas se conviertan y mantengan como líderes en su gestión. Utilizamos las últimas tecnologías para llevarte desde la ideación y la estrategia pasando por la creación de software, testing, mantenimiento y soporte.
      </Section>
      <Section title="Características">
        <div className="list-services">
          <CardInfo description="Análisis de sistemas." />
          <CardInfo description="Arquitectura de software." />
          <CardInfo description="Diseño de bases de datos." />
          <CardInfo description="Diseño de software." />
          <CardInfo description="Implementación." />
          <CardInfo description="Prueba y revisión." />
          <CardInfo description="Introducción y servicio operativo." />
          <CardInfo description="Mantenimiento y cuidado." />
          <CardInfo description="Documentación." />
          <CardInfo description="Soporte de segundo y tercer nivel." />
          
        </div>
      </Section>
      <Section title='Servicios' light>
          <div className="list-services">
            <CardService
              image={require('./../assets/image/saas/saas1.jpg')}
              title='Desarrollo de software a medida'
              description='Análisis e implantación de softwares, creación de programas informáticos personalizados brindando soluciones según las necesidades y requerimientos de la empresa.'
            />
            <CardService
              image={require('./../assets/image/saas/saas4.png')}
              title='Implementación de sistema web'
              description='Sistemas desarrollados 100% para internet, otorgando soluciones y acciones de comunicación articuladas con nuevas tecnologías y puestas al servicio de la estrategia empresarial.'
            />
             <CardService
              image={require('./../assets/image/saas/saas7.jpg')}
              title='Programación para dispositivos móviles'
              description='Creación de aplicaciones para llevar a cabo o facilitar tareas en un dispositivo informático. Compatibilidad para Android e iOS.'
            />
          </div>
        </Section>

      {/* <Section title="Aplicaciones y complementos" light>
        <div className="list-services">
          {data.map(
            (card, index) =>
              card.type === "service" && (
                <CardProject
                  key={index}
                  image={card.image}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  route={card.route}
                />
              )
          )}
        </div>
      </Section> */}
    </Master>
  );
}
const data = [
  {
    image: require("../assets/image/madit/1-Madit.jpg"),
    icon: "io-bpo-fav",
    title: "BPO",
    description: "Externalización de Procesos de Negocios",
    route: "/bpo",
    type: "service",
  },
  {
    image: require("../assets/image/madit/5-BiometriaFacialMadit.jpg"),
    icon: "io-madit-fav",
    title: "MADIT",
    description: "Sistema de validación biométrica y dactilar",
    route: "/madit",
    type: "service",
  },
];
