import React from "react";
import Carousel from "react-multi-carousel";
import {Section} from "./section";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 2560, min: 1440},
    items: 8
  },
  desktop: {
    breakpoint: {max: 1440, min: 1024},
    items: 6
  },
  tablet: {
    breakpoint: {max: 1024, min: 768},
    items: 4
  },
  mobile: {
    breakpoint: {max: 768, min: 0},
    items: 2
  }
};

export class CarouselClient extends React.Component {
  render() {
    return (
     <Section title='Clientes' light>
       <Carousel
         swipeable={true}
         draggable={true}
         showDots={true}
         responsive={responsive}
         ssr={true}
         infinite={true}
         autoPlay={true}
         autoPlaySpeed={2000}
         keyBoardControl={true}
         customTransition=".5s ease"
         transitionDuration={500}
         containerClass="carousel-container"
         removeArrowOnDeviceType={["tablet", "mobile"]}
       >
         {this.props.children}
       </Carousel>
     </Section>
    )
  }
}

export const Client = (props) => (
  <div className='io-client'>
    <img src={props.image} alt="client"/>
  </div>
)