import React from "react";
import {Icon} from "../../io/io";
import {Link} from "react-router-dom";

class CardProject extends React.Component {
  render() {
    return (
      <Link to={this.props.route} className="io-card project" onClick={this.props.onClick}>
        <div className="image">
          <img src={this.props.image} alt="image"/>
        </div>
        <div className="info">
          <Icon icon={this.props.icon}/>
          <div className="text">
            <p>{this.props.title}</p>
            <p>{this.props.description}</p>
          </div>
        </div>
      </Link>
    )
  }
}
export default CardProject